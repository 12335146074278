import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { HashLink } from "react-router-hash-link";
import classNames, { default as cx } from "classnames";

// import draftjsToMd from "../../utils/CustomDraftjsConverters/draftjsToMd";
// import mdToDraftjs from "../../utils/CustomDraftjsConverters/mdToDraftjs";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import {
  difficultyLevels,
  subFieldOptions,
  fieldOptionsMap,
  fieldOptionsMapMarkBy,
  fieldOptionsMarkBy,
  newFieldOptionsMarkBy,
} from "./data";
import SunEditorUI from "../../../components/UI/SunEditor/SubEditor";
import IconLabelButton from "../OldPortalComponents/IconLabelButton";
import TableEditor from "../OldPortalComponents/TableEditor";
import CheckRadio from "../OldPortalComponents/CheckRadio";
import Checkbox from "../OldPortalComponents/Checkbox";
import TextInput from "../OldPortalComponents/TextInput";
import BulletEditor from "../OldPortalComponents/QuizBulletEditor";
import MatchEditor from "../OldPortalComponents/MatchEditor/MatchEditor";
import ButtonToggle from "../OldPortalComponents/ButtonToggle";
import mdToDraftjs from "../../../components/UI/RichTextArea/converters/mdToDraftJs";
import draftjsToMd from "../../../components/UI/RichTextArea/converters/draftJsToMd";
import Dropdown from "../OldPortalComponents/Dropdown";
import InfoBlock from "../OldPortalComponents/InfoBlock";
import RichTextAreaQuiz from "../../../components/UI/RichTextArea/RichTextAreaQuiz";
import QuizTableEditor from "../OldPortalComponents/QuizTableEditor";
import HeaderDescriptionEditor from "../OldPortalComponents/HeaderDescriptionEditor";
import QuizModal from "../QuizModal";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  Button,
  Card,
  Image,
  useAccordionToggle,
} from "react-bootstrap";
import chevronRight from "../../../assets/images/chevRight.svg";
import tagInfoicon from "../../../assets/New-images/icon-tag-info.svg";
import linkkeywords from "../../../assets/images/link-keywords.svg";
import { TagsComponent } from "../../Knowledge/AllKnowledge";
import { useDispatch } from "react-redux";
import {
  getMcqDistractors,
  updateKeywordsOfQuestion,
} from "../../../store/quizzes/actions";
import AddTags from "../../../components/UI/AddTags/AddTags";
import QuizzesDocumentGroup from "../../../components/UI/QuizzesButtonsGroup/QuizzesDocumentGroup";
import {
  getQuizQuestionBankGenerateTags,
  questionBankLinkDocumentSections,
} from "../../../store/quizzes/actions";
import { HelperService } from "../../../services/helper.service";
import RubricQuizTableEditor from "../OldPortalComponents/RubricQuizTableEditor";
import * as _ from "lodash";
import RubricDescription from "../OldPortalComponents/RubricDescription";
import yellowBulb from "../../../assets/images/yellow-bulb.svg";

const Question = ({
  questionData,
  questionNumber,
  enableLearning,
  sendSubquestionsData,
  questionId,
  parentId,
  onDelete,
  forDisplay,
  markRed,
  errorMessage,
  passDataToParent,
  isNew,
  showKeywordsOptions,
  allDocuments,
  documentId,
  isEssayGrader,
}: any) => {
  const { t } = useTranslation();
  const helper = new HelperService();
  const dispatch = useDispatch();
  const [questionText, setQuestionText] = useState(
    questionData.text ? questionData.text : ""
  );
  const [allTags, setAllTags] = useState(
    questionData.tags ? questionData.tags : []
  );
  const [docSections, setDocSections] = useState(
    questionData.document_sections ? questionData.document_sections : []
  );
  const [showTagsModal, setShowTagsModal] = useState(false);
  // const [showKeywordsOptions, setShowKeywordsOptions] = useState(false);
  const [showConfirmGenerateKeys, setShowConfirmGenerateKeys] = useState(false);
  const [acctiveAcc, setActiveAcc] = useState("0");
  const hideOldBullet = questionData.type !== "bullet";

  const [rubricPositionCheck, setRubricPositionCheck] = useState(
    questionData.position_check
      ? questionData.position_check
      : "rubric_description"
  );

  const hideEssayOption = questionData.type !== "essay" && !isEssayGrader;

  const convertToEditorFormat = (data: any) => {
    const rawData = mdToDraftjs(data, {});
    const contentState = convertFromRaw(rawData);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  };
  const convertObjectToString = (obj: any) => {
    let newString = "";
    Object.keys(obj).forEach((keyword) => {
      newString = newString + `${keyword} = ${obj[keyword]}, `;
    });
    return newString;
  };

  // const toggleAcc = (eventKey, () => {})

  const convertToBulletFormat = (data: any) => {
    const convertedData: any = [];
    data.forEach((item: any) => {
      convertedData.push({
        ...item,
        keyword_scores: convertObjectToString(
          item.keyword_scores ? item.keyword_scores : {}
        ),
      });
    });
    return convertedData;
  };
  const [idealAnswer, setIdealAnswer] = useState(
    questionData.ideal_answer &&
      (questionData.type !== "table" || questionData.type !== "description")
      ? convertToEditorFormat(questionData.ideal_answer)
      : EditorState.createEmpty()
  );
  const [idealAnswerItems, setIdealAnswerItems] = useState(
    questionData.ideal_answer_items &&
      questionData.position_check !== "rubric_description"
      ? convertToBulletFormat(questionData.ideal_answer_items)
      : [
          { text: "", keyword_scores: "", score: 1 },
          { text: "", keyword_scores: "", score: 1 },
        ]
  );
  const [positionCheck, setPositionCheck] = useState(
    "position_check" in questionData ? questionData.position_check : null
  );

  const [questionOptions, setQuestionOptions] = useState(
    questionData.options ? [...questionData.options] : []
  );
  const [questionScore, setQuestionScore] = useState(
    "score" in questionData &&
      questionData.position_check !== "rubric_description"
      ? questionData.score === 0
        ? 1
        : questionData.score
      : questionData.type === "description"
      ? 2
      : 1
  );
  const [questionType, setQuestionType] = useState(
    questionData.type ? questionData.type : ""
  );
  const [answerKeySettings, setAnswerKeySettings] = useState(
    questionData.answer_key_settings &&
      questionData.position_check !== "rubric_description"
      ? questionData.answer_key_settings
      : {}
  );

  const [tableAnswer, setTableAnswer] = useState(
    questionData.ideal_answer &&
      (questionData.type === "table" || questionData.type === "description")
      ? JSON.parse(questionData.ideal_answer)
      : null
  );
  const [matchAnswer, setMatchAnswer] = useState(
    questionData.matches && questionData.type === "matching"
      ? questionData.matches
      : null
  );
  const [keywordsData, setKeywordsData] = useState(null);
  const [scoredBy, setScoredBy] = useState<any>(
    questionData.scored_by
      ? {
          value: questionData.scored_by,
          label: fieldOptionsMapMarkBy[questionData.scored_by],
        }
      : fieldOptionsMarkBy[0]
  );
  const [baseScore, setBaseScore] = useState(
    questionData.base_score ? questionData.base_score : 1
  );
  const [headerScore, setHeaderScore] = useState(
    "base_score_1" in questionData ? questionData.base_score_1 : 1
  );
  const [addHeaderScore, setAddHeaderScore] = useState(
    "base_score_1" in questionData && questionData.base_score_1 !== null
      ? true
      : false
  );
  // const [passData, setPassData] = useState(false);
  const [updateColumnHeader, setUpdateColumnHeader] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [enterComplete, setEnterComplete] = useState({
    status: false,
    identifier: "",
    index: "",
  });
  const [turnOnEnhancedKeywords, setTurnOnEnhancedKeywords] = useState(
    questionData.scored_by === "enhanced_keywords" ? true : false
  );
  const [newTableRowCount, setNewTableRowCount] = useState(2);

  const [newTableColumnCount, setNewTableColumnCount] = useState(2);
  const [difficultyLevel, setDifficultyLevel] = useState(
    questionData.hasOwnProperty("difficulty_level")
      ? {
          value: questionData.difficulty_level,
          label: questionData.difficulty_level.toString(),
        }
      : questionData.difficulty_level
      ? {
          value: questionData.difficulty_level,
          label: questionData.difficulty_level.toString(),
        }
      : difficultyLevels[0]
  );

  const [rubricDescIdealAnswerItems, setRubricDescIdealAnswerItems] = useState(
    questionData.ideal_answer_items &&
      questionData.position_check === "rubric_description"
      ? convertToBulletFormat(questionData.ideal_answer_items)
      : [
          { text: "", keyword_scores: "", score: 1 },
          { text: "", keyword_scores: "", score: 1 },
        ]
  );

  const [rubricDescAnswerKeySettings, setRubricDescAnswerKeySettings] =
    useState(
      questionData.answer_key_settings &&
        questionData.position_check === "rubric_description"
        ? { ...questionData.answer_key_settings, headers: [
          "Score Criteria",
          "Score for Criteria",
          "Description of Scoring Criteria",
        ],}
        : {
            headers: [
              "Score Criteria",
              "Score for Criteria",
              "Description of Scoring Criteria",
            ],
          }
    );

  const [rubricDescQuestionScore, setRubricDescQuestionScore] = useState(
    "score" in questionData &&
      questionData.type === "essay" &&
      questionData.position_check === "rubric_description"
      ? questionData.score
      : 1
  );

  const [rubricDescRowCount, setRubricDescRowCount] = useState(1);

  useEffect(() => {
    if (
      !forDisplay &&
      "keyword_scores" in questionData &&
      questionData.keyword_scores !== null
    ) {
      let temp = convertKeywordsToTable(questionData.keyword_scores);
      setKeywordsData(temp);
    }
    if (
      "answer_key_settings" in questionData &&
      questionData.answer_key_settings.row_count
    ) {
      setNewTableRowCount(questionData.answer_key_settings.row_count);
      setNewTableColumnCount(questionData.answer_key_settings.column_count);
      setRubricDescRowCount(questionData.answer_key_settings.row_count);
    }
  }, []);

  useEffect(() => {
    if (
      answerKeySettings &&
      answerKeySettings.headers &&
      answerKeySettings.headers.length !== 0
    ) {
      if (questionType === "essay") {
        let matches: any = [
          ...answerKeySettings.headers.map((score: any) => {
            let numbers = score.match(/\d+/g);
            if (numbers && numbers.length > 0) {
              numbers = numbers.map((i: any) => Number(i));
              return Math.max(...numbers);
            }
            return 0;
          }),
        ];

        if (matches && matches.length > 0) {
          // const score = matches.reduce((a: any, b: any) => a + b, 0);
          // setQuestionScore(score <= 0 ? 1 : score);
          let maximum = Math.max(...matches);
          if (maximum) {
            setQuestionScore(newTableRowCount * maximum);
          }
        }
      }

      // const max = Math.max(...answerKeySettings.headers.map((o: any) => o));
      // if (questionType === "essay") {
      //   setQuestionScore(newTableRowCount * max);
      // }
    }
  }, [answerKeySettings, newTableRowCount]);

  const passSubQuestionData = () => {
    if (!forDisplay) {
      let temp: any = {};
      temp.id = questionId;
      temp.text = questionText;
      temp.type = questionType;
      temp.score =
        questionType === "essay" && rubricPositionCheck === "rubric_description"
          ? +rubricDescQuestionScore
          : parseInt(questionScore);
      temp.difficulty_level = difficultyLevel.value || 1;
      if (questionType === "radio" || questionType === "checkbox") {
        temp.options = questionOptions;
      }
      if (questionType === "text" || questionType === "bullet") {
        temp.ideal_answer = convertToMarkdown(idealAnswer);
      }
      if (questionType === "newbullet") {
        temp.ideal_answer = convertToMarkdown(idealAnswer);
        let transformedIdealAnswerItems: any = [];
        idealAnswerItems.forEach((item: any) => {
          transformedIdealAnswerItems.push({
            ...item,
            keyword_scores: convertStringToObject(item.keyword_scores),
            score: "score" in item ? Number(item.score) : 1,
            scored_by: scoredBy.value,
          });
        });
        temp.ideal_answer_items = transformedIdealAnswerItems;
      }
      if (
        ["text", "table", "bullet", "description", "newbullet"].indexOf(
          questionType
        ) > -1
      ) {
        temp.scored_by = scoredBy.value;
      }
      if (questionType === "table") {
        let settings = { ...answerKeySettings };
        // settings.row_count = newTableRowCount;
        settings.column_count = newTableColumnCount;
        temp.answer_key_settings = settings;
        // setAnswerKeySettings(temp.answer_key_settings);
        let transformedIdealAnswerItems: any = [];
        idealAnswerItems.forEach((item: any) => {
          transformedIdealAnswerItems.push({
            ...item,
            keyword_scores: convertStringToObject(item.keyword_scores),
            score: "score" in item ? Number(item.score) : 1,
            scored_by: scoredBy.value,
          });
        });
        settings.row_count = transformedIdealAnswerItems.length/newTableColumnCount;
        temp.ideal_answer_items = transformedIdealAnswerItems;
      }
      if (
        questionType === "essay" &&
        rubricPositionCheck === "rubric_breakdown"
      ) {
        let settings = { ...answerKeySettings };
        settings.row_count = newTableRowCount;
        settings.column_count = newTableColumnCount;
        settings.column_headers = columnHeaders;
        temp.answer_key_settings = settings;
        setAnswerKeySettings(temp.answer_key_settings);
        let transformedIdealAnswerItems: any = [];
        idealAnswerItems.forEach((item: any) => {
          transformedIdealAnswerItems.push({
            ...item,
            keyword_scores: convertStringToObject(item.keyword_scores),
            score: "score" in item ? Number(item.score) : 1,
            scored_by: scoredBy.value,
          });
        });
        temp.ideal_answer_items = transformedIdealAnswerItems;
      }
      if (
        questionType === "essay" &&
        rubricPositionCheck === "rubric_description"
      ) {
        let settings = { ...rubricDescAnswerKeySettings };
        settings.row_count = rubricDescRowCount;
        settings.column_count = newTableColumnCount;

        temp.answer_key_settings = settings;
        setRubricDescAnswerKeySettings(temp.answer_key_settings);
        let transformedIdealAnswerItems: any = [];
        rubricDescIdealAnswerItems.forEach((item: any) => {
          transformedIdealAnswerItems.push({
            ...item,
            keyword_scores: convertStringToObject(item.keyword_scores),
            score: "score" in item ? Number(item.score) : 1,
            scored_by: scoredBy.value,
          });
        });

        // Work around for column headers
        if ("column_headers" in settings) {
          settings.row_count = transformedIdealAnswerItems.length;
          settings.column_headers = settings.column_headers.slice(
            0,
            transformedIdealAnswerItems.length
          );
        }
        // Work around for column headers
        temp.ideal_answer_items = transformedIdealAnswerItems;
      }
      if (questionType === "description") {
        let settings = { ...answerKeySettings };
        settings.column_headers = [];
        settings.headers = ["", ""];
        settings.enable_column_header = false;
        temp.answer_key_settings = settings;
        temp.ideal_answer = JSON.stringify(tableAnswer);
      }
      if (questionType === "matching") {
        let settings = { ...answerKeySettings };
        settings.column_headers = [];
        settings.enable_column_header = false;
        temp.answer_key_settings = settings;
        temp.matches = matchAnswer;
      }
      if (scoredBy.value === "keywords" && isTableValid()) {
        temp.keyword_scores = convertTableToKeywords();
      } else {
        temp.keywords = null;
      }
      if (
        questionType === "bullet" ||
        questionType === "description" ||
        questionType === "newbullet"
      ) {
        temp.base_score = parseInt(baseScore);
      }
      if (questionType === "description") {
        temp.base_score_1 =
          headerScore && addHeaderScore ? parseInt(headerScore) : null;
      }
      if (
        questionType === "bullet" ||
        questionType === "table" ||
        questionType === "essay" ||
        questionType === "newbullet" ||
        questionType === "description"
      ) {
        // temp.position_check = positionCheck;
        temp.position_check =
          questionType === "essay" ? rubricPositionCheck : positionCheck;
      }
      temp.parent_id = parentId;
      setOpenEditPopup(false);
      sendSubquestionsData(temp, questionId);
    }
  };

  const onQuestionChange = (value: any) => {
    setQuestionText(value);
    // setPassData((data) => !data);
  };

  const onAnswerChange = (value: any) => {
    setIdealAnswer(value);
    // setPassData((data) => !data);
  };

  const onTableChange = (value: any) => {
    setTableAnswer(value);
    // setPassData((data) => !data);
  };

  const onAnswerKeyTableChange = (
    value: any,
    rowCount: any,
    columnCount: any
  ) => {
    setIdealAnswerItems(value);
    setNewTableRowCount(rowCount);
    setNewTableColumnCount(columnCount);
  };

  const onRubricDescAnswerKeyTableChange = (
    value: any,
    rowCount: any,
    columnCount: any
  ) => {
    let matches: any = [
      ...value
        .filter((e: any, i: any) => i % 2 === 0)
        .map((val: any) => {
          let numbers = val.text.match(/\d+/g);
          if (numbers && numbers.length > 0) {
            numbers = numbers.map((i: any) => Number(i));
            return Math.max(...numbers);
          }
          return 0;
        }),
    ];

    if (matches && matches.length > 0) {
      setRubricDescQuestionScore(matches.reduce((a: any, b: any) => a + b, 0));
    }
    setRubricDescIdealAnswerItems(value);
    setRubricDescRowCount(rowCount);
    setNewTableColumnCount(columnCount);
  };

  const handleQuestionCardDelete = (id: any) => {
    let temp = [...idealAnswerItems];
    temp.splice(id, 1);
    setIdealAnswerItems(temp);
    let newMaxScore = 0;
    temp.forEach((item) => {
      newMaxScore += Number(item.score);
    });
    setQuestionScore(newMaxScore.toString());
  };

  const onQuestionEdit = (event: any, id: any) => {
    let sentences = event.target.value.split("\n");
    let temp = [...idealAnswerItems];

    if (sentences.length <= 1) {
      temp[id].text = event.target.value;
      setIdealAnswerItems(temp);
    }
  };

  const keywordsInputChange = (event: any, id: any) => {
    let temp = [...idealAnswerItems];
    temp[id].keyword_scores = event.target.value;
    setIdealAnswerItems(temp);
  };

  const pointScoreChange = (event: any, id: any) => {
    let temp = [...idealAnswerItems];
    temp[id].score = event.target.value;
    setIdealAnswerItems(temp);
    let newMaxScore = 0;
    temp.forEach((item) => {
      newMaxScore += Number(item.score);
    });
    setQuestionScore(newMaxScore.toString());
  };

  const handlePaste = (event: any, id: any) => {
    let temp = event.clipboardData
      ? event.clipboardData.getData("text")
      : event.target.value;
    let sentences = temp.split("\n");

    let tempAnswer = [...idealAnswerItems];

    if (sentences.length > 1) {
      let count = 0;
      sentences.forEach((sentence: any) => {
        if (sentence.trim()) {
          tempAnswer.splice(id + count, 0, {
            text: sentence.trim(),
            keyword_scores: "",
          });
          count += 1;
        }
      });
    }

    setIdealAnswerItems(tempAnswer);
    let newMaxScore = 0;
    tempAnswer.forEach((item) => {
      newMaxScore += Number(item.score);
    });
    setQuestionScore(newMaxScore.toString());
  };

  useEffect(() => {
    if (enterComplete.status) {
      let element = document.getElementById(
        `input${enterComplete.identifier.toString()}${enterComplete.index.toString()}`
      );
      if (element) {
        element.focus();
      }
    }
  }, [enterComplete]);

  const handleBulletInputEnter = (event: any, id: any) => {
    if (event.key === "Enter" || event.keyCode === 13 || event.which === 13) {
      let tempAnswer = [...idealAnswerItems];

      tempAnswer.splice(id + 1, 0, { text: "", keyword_scores: "", score: 1 });
      setIdealAnswerItems(tempAnswer);
      setEnterComplete({
        status: true,
        identifier: questionId,
        index: id + 1,
      });
      setQuestionScore((data: any) => (Number(data) + 1).toString());
    }
  };

  const onMatchEditorChange = (value: any) => {
    setMatchAnswer(value);
    // setPassData((data) => !data);
  };

  const onKeywordsTableChange = (value: any) => {
    setKeywordsData(value);
    // setPassData((data) => !data);
  };

  const handleTableHeaderChange = (value: any) => {
    let temp = { ...answerKeySettings };
    temp.headers = value;
    setAnswerKeySettings(temp);
    // setPassData((data) => !data);
  };

  const handleRubricTableHeaderChange = (value: any) => {
    let temp = { ...rubricDescAnswerKeySettings };
    temp.headers = value;
    setRubricDescAnswerKeySettings(temp);
  };

  const handleRubricDescVerticalTableHeaderChange = (value: any) => {
    let temp = { ...rubricDescAnswerKeySettings };
    temp.column_headers = value;
    setRubricDescAnswerKeySettings(temp);
  };

  const [columnHeaders, setColumnHeaders] = useState(
    questionData.answer_key_settings?.column_headers
      ? questionData.answer_key_settings.column_headers
      : {}
  );

  const handleVerticalTableHeaderChange = (value: any) => {
    if (questionType === "essay") {
      setColumnHeaders(value);
    } else {
      let temp = { ...answerKeySettings };
      temp.column_headers = value;
      setAnswerKeySettings(temp);
    }
  };

  const updateEnableColumnHeader = (value: any) => {
    let temp = { ...answerKeySettings };
    temp.enable_column_header = value;
    setAnswerKeySettings(temp);
    // setPassData((data) => !data);
  };

  const onOptionChange = (value: any, optionIndex: any) => {
    let temp = [...questionOptions];
    temp[optionIndex] = {
      ...temp[optionIndex],
      text: value,
      id: temp[optionIndex].id,
      is_correct: temp[optionIndex].is_correct,
    };
    setQuestionOptions(temp);
    // setPassData((data) => !data);
  };

  const onTypeChange = (value: any) => {
    setQuestionType(value);
    if (value !== "radio") {
      let options = [...questionOptions];
      options.forEach((option) => {
        delete option.branch;
      });

      setQuestionOptions(options);
      // setPassData((data) => !data);
    }

    // if (value === "checkbox" || value === "radio") {
    //   // let options = [...questionOptions];
    //   // if (options.length === 0 && idealAnswer.getCurrentContent().hasText()) {
    //   //   options.push({
    //   //     id: uuidv4(),
    //   //     text: convertToMarkdown(idealAnswer),
    //   //     is_correct: false,
    //   //   });
    //   //   setQuestionOptions(options);
    //   //   // setPassData((data) => !data);
    //   // }
    //   let temp: any = [...idealAnswerItems];
    //   let data = questionData?.options?.filter((e: any) => e.text.trim().length !== 0);
    //   temp = temp.filter((e: any) => e.text.trim().length !== 0);

    //   if (
    //     temp.length >= 1 &&
    //     questionData.type !== "table" &&
    //     questionData.type !== "description" &&
    //     questionData.type !== "matching"
    //   ) {
    //     const data: any = [];
    //     temp.map((e: any) => {
    //       data.push({
    //         id: uuidv4(),
    //         text: e.text.trim(),
    //         is_correct: false,
    //       });
    //     });

    //     if (data && data.length !== 0) {
    //       setQuestionOptions([...data]);
    //     }
    //     // setQuestionOptions([
    //     //   {
    //     //     id: uuidv4(),
    //     //     text: idealAnswerItems[0].text,
    //     //     is_correct: false,
    //     //   },
    //     //   {
    //     //     id: uuidv4(),
    //     //     text: "",
    //     //     is_correct: false,
    //     //   },
    //     // ]);
    //   } else if (
    //     idealAnswer.getCurrentContent().hasText() &&
    //     temp.length === 0
    //   ) {
    //     setQuestionOptions([
    //       {
    //         id: uuidv4(),
    //         text: convertToMarkdown(idealAnswer),
    //         is_correct: false,
    //       },
    //       {
    //         id: uuidv4(),
    //         text: "",
    //         is_correct: false,
    //       },
    //     ]);
    //   } else if(data.length > 0) {
    //     setExecuteGenerateDistractors(true);
    //   } else {
    //     return;
    //   }
    //   setExecuteGenerateDistractors(true);
    // }

    if (value === "essay") {
      setAnswerKeySettings({
        ...answerKeySettings,
        headers: ["", "", ""],
        column_headers: [""],
        enable_column_header: false,
      });
    }

    if (value === "radio" || value === "checkbox") {
      let temp: any = [...idealAnswerItems];
      temp = temp.filter((e: any) => e.text.trim().length !== 0);
      let options = questionData?.options?.filter(
        (e: any) => e.text.trim().length !== 0
      );
      if (
        temp.length >= 1 &&
        questionData?.type !== "table" &&
        questionData?.type !== "description" &&
        questionData?.type !== "matching" &&
        (!options || options.length === 0) &&
        !idealAnswer.getCurrentContent().hasText()
      ) {
        const data: any = [];
        temp.map((e: any) => {
          data.push({
            id: uuidv4(),
            text: e.text.trim(),
            is_correct: false,
          });
        });

        if (data && data.length !== 0) {
          setQuestionOptions([...data]);
        }
        // setQuestionOptions([
        //   {
        //     id: uuidv4(),
        //     text: idealAnswerItems[0].text,
        //     is_correct: false,
        //   },
        //   {
        //     id: uuidv4(),
        //     text: "",
        //     is_correct: false,
        //   },
        // ]);
      } else if (
        idealAnswer.getCurrentContent().hasText() &&
        temp.length === 0 &&
        (!options || options.length === 0)
      ) {
        setQuestionOptions([
          {
            id: uuidv4(),
            text: convertToMarkdown(idealAnswer),
            is_correct: false,
          },
          {
            id: uuidv4(),
            text: "",
            is_correct: false,
          },
        ]);
      } else if (
        options &&
        options.length > 0 &&
        !idealAnswer.getCurrentContent().hasText() &&
        (!temp || temp.length === 0)
      ) {
        setExecuteGenerateDistractors(true);
      } else {
        return;
      }
      setExecuteGenerateDistractors(true);
    }
  };

  const onScoredByChange = (value: any) => {
    setScoredBy(value);
    if (value.value === "enhanced_keywords") {
      setTurnOnEnhancedKeywords(true);
    } else {
      setTurnOnEnhancedKeywords(false);
    }
    // setPassData((data) => !data);
  };

  const onScoreChange = (event: any) => {
    setQuestionScore(
      Number.isNaN(event.target.value) ? "0" : event.target.value
    );
    // setPassData((data) => !data);
  };

  const onBaseScoreChange = (event: any) => {
    setBaseScore(Number.isNaN(+event.target.value) ? "0" : +event.target.value);
    // setPassData((data) => !data);
  };

  const onHeaderScoreChange = (event: any) => {
    setHeaderScore(
      Number.isNaN(+event.target.value) ? "0" : +event.target.value
    );
    // setPassData((data) => !data);
  };

  const onOptionDelete = (optionId: any) => {
    let temp = [...questionOptions];
    questionOptions.forEach((option, index) => {
      if (option.id === optionId) {
        temp[index] = "remove";
      } else {
        temp[index] = {
          ...option,
          id: option.id,
          text: option.text,
          is_correct: option.is_correct,
        };
      }
    });
    let opt = [];
    opt = temp.filter(function (el) {
      return el !== "remove";
    });
    setQuestionOptions(opt);
    // setPassData((data) => !data);
  };

  const handleCheckboxCorrect = (isChecked: any, optionIndex: any) => {
    let temp = [...questionOptions];
    temp[optionIndex].is_correct = isChecked;
    setQuestionOptions(temp);
    // setPassData((data) => !data);
  };

  const handleRadioCorrect = (value: any) => {
    let temp = [...questionOptions];
    temp.forEach((option) => {
      option.is_correct = false;
    });
    temp[parseInt(value)].is_correct = true;
    setQuestionOptions(temp);
    // setPassData((data) => !data);
  };

  const addOption = () => {
    let temp = [...questionOptions];
    temp.push({
      id: uuidv4(),
      text: "",
      is_correct: false,
    });
    setQuestionOptions(temp);
    // setPassData((data) => !data);
  };

  const convertTableToKeywords = () => {
    let data: any = {};
    keywordsData.forEach((row: any) => {
      data[row[0]] = parseFloat(row[1]);
    });
    return data;
  };

  const convertKeywordsToTable = (data: any) => {
    let temp: any = [];
    if (data) {
      Object.keys(data).forEach((keyword) => {
        temp.push([keyword, data[keyword]]);
      });
      return temp;
    }
  };

  const convertStringToObject = (str: any) => {
    let allKeywords = str.split(",");
    let temp: any = {};
    allKeywords.forEach((item: any) => {
      let keywordWithScore = item.split("=");
      if (keywordWithScore[0]) {
        temp[keywordWithScore[0].trim()] = keywordWithScore[1]
          ? Number(keywordWithScore[1].trim())
          : 1;
      }
    });
    return temp;
  };

  const isTableValid = () => {
    let isValid = false;
    keywordsData &&
      keywordsData.forEach((row: any) => {
        if (row.filter((cell: any) => cell !== "").length > 0) {
          isValid = true;
        }
      });
    return isValid;
  };

  const convertToMarkdown = (data: any) => {
    const content = data.getCurrentContent();
    let markup = draftjsToMd(convertToRaw(content), null);
    let splitMarkup = markup.replace("&nbsp;", " ");

    let transformedMarkup = splitMarkup.trim();
    return transformedMarkup;
  };

  const onDifficultyLevelChange = (value: any) => {
    setDifficultyLevel(value);
  };

  useEffect(() => {
    passSubQuestionData();
  }, [
    questionText,
    acctiveAcc,
    idealAnswer,
    idealAnswerItems,
    positionCheck,
    questionOptions,
    questionScore,
    questionType,
    answerKeySettings.headers,
    tableAnswer,
    matchAnswer,
    keywordsData,
    scoredBy,
    baseScore,
    headerScore,
    addHeaderScore,
    difficultyLevel,
    columnHeaders,
    rubricDescIdealAnswerItems,
    rubricPositionCheck,
    rubricDescQuestionScore,
  ]);

  const getQuestionHTML = () => {
    const oldOptions = [...subFieldOptions];
    if (isNew) {
      oldOptions.splice(3, 1);
    }
    const options = hideOldBullet
      ? oldOptions.filter((o: any) => o.value !== "bullet")
      : oldOptions;

    const opt = hideEssayOption
      ? options.filter((o: any) => o.value !== "essay")
      : options;

    return (
      <div className="editable-question">
        <div className="flex-container">
          <div className="flex-one">
            <div className="flex-container">
              <div className="flex-one-no-padding">
                <Dropdown
                  label={t("Type")}
                  data={
                    enableLearning
                      ? opt.concat([
                          {
                            value: "matching",
                            label: t("Matching"),
                          },
                        ])
                      : opt
                  }
                  value={{
                    value: questionType,
                    label: fieldOptionsMap[questionType],
                  }}
                  defaultValue={{
                    value: "radio",
                    label: t("Multiple Choice (select one)"),
                  }}
                  onChange={(value: any) => onTypeChange(value.value)}
                />
                <div className="half-clearfix" />
              </div>
              <div className="quiz-guide-btn-container">
                <HashLink
                  smooth
                  to={`/quiz-guide#${questionType}`}
                  className="quiz-guide-btn"
                >
                  <i className="icon-help-circle" />
                </HashLink>
              </div>
            </div>
            {questionType === "essay" && 
              <div className="d-flex page-warning">
                <img src={yellowBulb} alt="yellowBulb" className="pr-3" />
                <span>
                  {t(
                    "Please keep in mind that learners' responses to essay questions are restricted to a maximum of 8000 characters which is approximately 2000 words."
                  )}
                </span>
              </div>
            }
            <SunEditorUI
              id={questionNumber}
              name={`question-editor`}
              editorType="sun"
              value={questionText}
              onChange={onQuestionChange}
              placeholder={t("Type question here")}
            />
          </div>
          <div className="side-padding">
            {(questionType === "radio" || questionType === "checkbox") && (
              <>
                <TextInput
                  value={questionScore.toString()}
                  inputType="text"
                  // min={0}
                  label={t("Max Question Score")}
                  // inputChange={onScoreChange}
                  inputChange={(event: any) => {
                    if (event.target.value.match(/^[0-9]*(\.[0-9]{0,2})?$/)) {
                      onScoreChange(event);
                    }
                  }}
                />
                <div className="clearfix" />
              </>
            )}

            {enableLearning === true &&
              ["text", "bullet", "table", "description", "newbullet"].indexOf(
                questionType
              ) > -1 && (
                <div className="flex-container">
                  <div className="flex-one-no-padding">
                    <Dropdown
                      label={t("Score by")}
                      data={
                        questionType === "newbullet" || questionType === "table"
                          ? newFieldOptionsMarkBy
                          : fieldOptionsMarkBy
                      }
                      value={scoredBy}
                      onChange={(value: any) => onScoredByChange(value)}
                    />
                    <div className="clearfix" />
                  </div>
                  <div className="quiz-guide-btn-container">
                    <HashLink
                      smooth
                      to={`/quiz-guide#${scoredBy.value}`}
                      className="quiz-guide-btn"
                    >
                      <i className="icon-help-circle" />
                    </HashLink>
                  </div>
                </div>
              )}

            {enableLearning === false &&
              (questionType === "text" ||
                questionType === "bullet" ||
                questionType === "newbullet" ||
                questionType === "table" ||
                questionType === "description") && (
                <>
                  <div className="half-clearfix" />

                  <InfoBlock>
                    {t("This question will not be scored.")}
                  </InfoBlock>
                </>
              )}

            {enableLearning === true &&
              (questionType === "text" ||
                questionType === "bullet" ||
                questionType === "newbullet" ||
                questionType === "table" ||
                questionType === "description" ||
                questionType === "matching" ||
                questionType === "essay") && (
                <>
                  <TextInput
                    value={
                      questionType === "essay" &&
                      rubricPositionCheck === "rubric_description"
                        ? rubricDescQuestionScore.toString()
                        : questionScore.toString()
                    }
                    inputType="text"
                    // min={0}
                    label={t("Max Question Score")}
                    inputChange={(event: any) => {
                      if (event.target.value.match(/^[0-9]*(\.[0-9]{0,2})?$/)) {
                        onScoreChange(event);
                      }
                    }}
                    readOnly={questionType === "essay"}
                    disabled={questionType === "essay"}
                  />
                  <div className="clearfix" />
                </>
              )}

            {enableLearning === true &&
              (questionType === "bullet" || questionType === "newbullet") &&
              scoredBy.value !== "keywords" &&
              scoredBy.value !== "enhanced_keywords" && (
                <>
                  {/* <TextInput
                    value={baseScore.toString()}
                    inputType="number"
                    min={0}
                    label={t("Score for each point")}
                    inputChange={onBaseScoreChange}
                  /> */}

                  <TextInput
                    value={baseScore.toString()}
                    inputType="text"
                    // min={0}
                    label={t("Score for each point")}
                    // inputChange={onScoreChange}
                    inputChange={(event: any) => {
                      if (event.target.value.match(/^[0-9]*(\.[0-9]{0,2})?$/)) {
                        onBaseScoreChange(event);
                      }
                    }}
                  />
                  <div className="clearfix" />
                </>
              )}

            <div className="flex-container">
              <div className="flex-one-no-padding">
                <Dropdown
                  dropdownContainerClassName="difficulty"
                  label={t("Difficulty Level")}
                  data={difficultyLevels}
                  value={difficultyLevel}
                  onChange={(value: any) => onDifficultyLevelChange(value)}
                />
                <div className="clearfix" />
              </div>
            </div>

            {enableLearning === true &&
              questionType === "description" &&
              scoredBy.value !== "keywords" && (
                <>
                  <TextInput
                    value={baseScore.toString()}
                    inputType="number"
                    min={0}
                    label={t("Score for each PAIR (header + description)")}
                    inputChange={onBaseScoreChange}
                  />
                  <div className="clearfix" />
                </>
              )}
            {enableLearning === true &&
              questionType === "description" &&
              scoredBy.value !== "keywords" && (
                <React.Fragment>
                  <Checkbox
                    checked={addHeaderScore}
                    label={t("Check to allot score for each headers")}
                    onCheckboxChange={(event: any) => {
                      setAddHeaderScore(event.target.checked);
                      setHeaderScore("");
                    }}
                  />
                  <div className="half-clearfix" />
                </React.Fragment>
              )}
            {enableLearning === true &&
              addHeaderScore &&
              questionType === "description" &&
              scoredBy.value !== "keywords" && (
                <>
                  <TextInput
                    value={headerScore.toString()}
                    inputType="number"
                    min={0}
                    label={t("Score per header")}
                    inputChange={onHeaderScoreChange}
                  />
                  <div className="clearfix" />
                </>
              )}
            <button
              className="btn btn-outline-danger font-600 border-0 py-1 ml-auto bg-none"
              onClick={() => onDelete(questionId)}
            >
              {t("Delete Question")}
            </button>
          </div>
        </div>
        {(questionType === "bullet" ||
          questionType === "table" ||
          questionType === "description" ||
          questionType === "newbullet") &&
          scoredBy.value !== "enhanced_keywords" && (
            <div className="flex-one">
              <div className="clearfix" />
              <h3 className="page-subheading">{t("Marking setting")}</h3>
              <ButtonToggle
                value={positionCheck}
                buttons={[
                  {
                    value: null,
                    label: t("Order doesn't matter"),
                  },
                  {
                    value: "order",
                    label: t("Answer should be in order"),
                  },
                  {
                    value: "placement",
                    label: t(
                      "All items in the answer should be in exact position"
                    ),
                  },
                ]}
                onChange={(value: any) => setPositionCheck(value)}
              />
              <div className="clearfix" />
            </div>
          )}

        {questionType === "essay" && (
          // scoredBy.value !== "enhanced_keywords" &&
          <div className="d-flex align-items-center justify-content-between px-0 mt-3">
            <h3 className="page-subheading mb-0 font-14">{t("Scoring Rubric ")}</h3>
            <button
              className="btn btn-link font-weight-bold font-14"
              onClick={() => setRubricPositionCheck((prev: any) => prev === "rubric_breakdown" ? "rubric_description" : "rubric_breakdown")}
            >
              {rubricPositionCheck === "rubric_breakdown"
                ? t("Switch to Scoring Rubric with Description")
                : t("Switch to Scoring Rubric with Breakdown")}
            </button>
          </div>
        )}
        <div
          className={`"flex-one px-0 mt-3 " ${
            questionType === "essay" ? "rubric-scoring-table" : ""
          } `}
        >
          {(questionType === "text" || questionType === "bullet") && (
            <>
              <h4 className="card-subheading mt-20">{t("Answer key")}</h4>

              <InfoBlock>
                {t(
                  "Paragraph and non-paragraph based answer have a different scoring mechanism. If the ideal answer has multiple parts please use bulleted or numbered list."
                )}
              </InfoBlock>

              <RichTextAreaQuiz
                key={questionNumber}
                value={idealAnswer}
                id={questionNumber}
                onChange={onAnswerChange}
                isQuizAnswer
              />
            </>
          )}
          {questionType === "table" && (
            <>
              <h4 className="card-subheading mt-20">{t("Answer key")}</h4>
              <QuizTableEditor
                onHeaderChange={handleTableHeaderChange}
                onColumnHeaderChange={handleVerticalTableHeaderChange}
                onAnswerKeyTableChange={onAnswerKeyTableChange}
                tableValues={idealAnswerItems}
                horizontalHeaderValues={
                  "headers" in answerKeySettings
                    ? answerKeySettings.headers
                    : ["", "", ""]
                }
                verticalHeaderValues={
                  "column_headers" in answerKeySettings
                    ? answerKeySettings.column_headers
                    : [""]
                }
                enableVerticalHeader={
                  "enable_column_header" in answerKeySettings
                    ? answerKeySettings.enable_column_header
                    : false
                }
                rowCount={
                  "row_count" in answerKeySettings
                    ? answerKeySettings.row_count
                    : null
                }
                columnCount={
                  "column_count" in answerKeySettings
                    ? answerKeySettings.column_count
                    : null
                }
                scoredBy={scoredBy}
              />
            </>
          )}
          {questionType === "description" && (
            <>
              <h4 className="card-subheading mt-20">{t("Answer key")}</h4>
              <HeaderDescriptionEditor
                onHeaderChange={handleTableHeaderChange}
                onTableChange={onTableChange}
                tableValues={tableAnswer}
                // headerValues={
                //   "headers" in answerKeySettings
                //     ? answerKeySettings.headers
                //     : [""]
                // }
              />
            </>
          )}
          {questionType === "matching" && enableLearning && (
            <>
              <h4 className="card-subheading mt-20">{t("Answer Key")}</h4>
              <MatchEditor
                data={matchAnswer}
                onChange={onMatchEditorChange}
                headerValues={
                  "headers" in answerKeySettings
                    ? answerKeySettings.headers
                    : ["", ""]
                }
                onHeaderChange={handleTableHeaderChange}
              />
            </>
          )}
          {questionType === "newbullet" && (
            <>
              <h4 className="card-subheading mt-20">{t("Answer Key")}</h4>
              <BulletEditor
                spellCheck={false}
                identifier={questionId}
                data={idealAnswerItems}
                handleCardDelete={handleQuestionCardDelete}
                onInputEdit={onQuestionEdit}
                handlePaste={handlePaste}
                handleKeyPress={handleBulletInputEnter}
                shouldAutoFocus={false}
                handleKeyUp={handleBulletInputEnter}
                turnOnKeywords={turnOnEnhancedKeywords}
                keywordsInputChange={keywordsInputChange}
                paraData={idealAnswer}
                onParaChange={onAnswerChange}
                pointScoreChange={pointScoreChange}
              />
            </>
          )}
          {questionType === "essay" &&
            rubricPositionCheck === "rubric_breakdown" && (
              <>
                <RubricQuizTableEditor
                  maxCharCount={4000}
                  onHeaderChange={handleTableHeaderChange}
                  onColumnHeaderChange={handleVerticalTableHeaderChange}
                  onAnswerKeyTableChange={onAnswerKeyTableChange}
                  tableValues={idealAnswerItems}
                  horizontalHeaderValues={
                    "headers" in answerKeySettings
                      ? answerKeySettings.headers
                      : ["", "", ""]
                  }
                  verticalHeaderValues={
                    "column_headers" in answerKeySettings
                      ? answerKeySettings.column_headers
                      : [""]
                  }
                  enableVerticalHeader={
                    "enable_column_header" in answerKeySettings
                      ? answerKeySettings.enable_column_header
                      : false
                  }
                  rowCount={
                    "row_count" in answerKeySettings
                      ? answerKeySettings.row_count
                      : null
                  }
                  columnCount={
                    "column_count" in answerKeySettings
                      ? answerKeySettings.column_count
                      : null
                  }
                  scoredBy={scoredBy}
                  essayGrader
                />
              </>
            )}
          {questionType === "essay" &&
            rubricPositionCheck === "rubric_description" && (
              <RubricDescription
                maxCharCount={4000}
                onHeaderChange={handleRubricTableHeaderChange}
                onColumnHeaderChange={handleRubricDescVerticalTableHeaderChange}
                onAnswerKeyTableChange={onRubricDescAnswerKeyTableChange}
                tableValues={rubricDescIdealAnswerItems}
                horizontalHeaderValues={
                  "headers" in rubricDescAnswerKeySettings
                    ? rubricDescAnswerKeySettings.headers
                    : [
                        "Score Criteria",
                        "Score for Criteria",
                        "Description of Scoring Criteria",
                      ]
                }
                verticalHeaderValues={
                  "column_headers" in rubricDescAnswerKeySettings
                    ? rubricDescAnswerKeySettings.column_headers
                    : [""]
                }
                enableVerticalHeader={
                  "enable_column_header" in rubricDescAnswerKeySettings
                    ? rubricDescAnswerKeySettings.enable_column_header
                    : false
                }
                rowCount={
                  "row_count" in rubricDescAnswerKeySettings
                    ? rubricDescAnswerKeySettings.row_count
                    : null
                }
                columnCount={
                  "column_count" in rubricDescAnswerKeySettings
                    ? rubricDescAnswerKeySettings.column_count
                    : null
                }
                scoredBy={scoredBy}
                essayGrader
              />
            )}
        </div>
      </div>
    );
  };

  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [generatingDistractors, setGeneratingDistractors] = useState(false);
  const [executeGenerateDistractors, setExecuteGenerateDistractors] =
    useState(false);

  useEffect(() => {
    setIsBtnDisable(
      !questionOptions.some((e, i) => {
        return e.is_correct && e.text.trim().length > 0;
      })
    );
  }, [questionOptions]);

  const getDistractors = async () => {
    try {
      let temp = [...questionOptions];
      // const emptyInputs = temp.filter((opt) => opt.text.trim().length <= 0);
      temp = temp.filter((e) => e.text.trim().length !== 0);
      const rawText = questionText.replace(/(<([^>]+)>)/gi, "");
      if (!rawText) {
        helper.showToaster(
          t("Please input question before generating distractors")
        );
        return;
      }

      setGeneratingDistractors(true);
      const data = {
        text: rawText.trim().length !== 0 && rawText,
        correct_options: temp
          .filter((opt) => opt.is_correct)
          .map((e) => e.text),
      };

      const response: any = await dispatch(getMcqDistractors(data));
      if (response && response.distractors.length > 0) {
        response.distractors.forEach((e: any) =>
          temp.push({
            id: uuidv4(),
            text: e,
            is_correct: false,
          })
        );
      }

      if (temp && temp.length !== 0) {
        temp = _.uniqBy(
          _.sortBy(temp, (e) => !e.is_correct),
          "text"
        );
        temp = _.shuffle(temp);
        // temp = [...temp, ...emptyInputs];
      }

      await setQuestionOptions([...temp]);
      setGeneratingDistractors(false);
    } catch (error) {
      setGeneratingDistractors(false);
    }
  };

  if (executeGenerateDistractors) {
    if (questionOptions && questionOptions.length >= 1) {
      handleRadioCorrect(0);
      if (questionType === "radio") {
        getDistractors();
      }
    }
    setExecuteGenerateDistractors(false);
  }

  const getOptionsHTML = () => {
    return (
      <>
        {(questionType === "radio" || questionType === "checkbox") && (
          <div className="options-editor">
            <h4 className="card-subheading">{t("Options")}</h4>
            <div className="flex-container">
              <div className="page-label">{t("Is correct")}</div>
              <div className="page-label flex-one">
                {t("Enter options below")}
              </div>
              {questionType === "radio" && (
                <button
                  className={classNames({
                    "btn page-label btn-link": true,
                  })}
                  disabled={isBtnDisable}
                  onClick={getDistractors}
                >
                  {t("Generate Distractors")}
                </button>
              )}
              <div className="page-label"></div>
            </div>
            {(questionType === "radio" || questionType === "checkbox") &&
              questionOptions.map((option, index) => (
                <div key={option.id}>
                  <div className="flex-container">
                    <div className="options-editor-checkbox">
                      {questionType === "checkbox" && (
                        <Checkbox
                          checked={option.is_correct}
                          onCheckboxChange={(event: any) =>
                            handleCheckboxCorrect(event.target.checked, index)
                          }
                        />
                      )}
                      {questionType === "radio" && (
                        <CheckRadio
                          name={questionText}
                          checked={option.is_correct}
                          onRadioChange={(event: any) =>
                            handleRadioCorrect(event.target.value)
                          }
                          value={index}
                        />
                      )}
                    </div>
                    <div className="flex-one no-padding">
                      <TextInput
                        // autoFocus
                        inputChange={(event: any) =>
                          onOptionChange(event.target.value, index)
                        }
                        value={option.text}
                      />
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onOptionDelete(option.id);
                      }}
                      className="options-editor-delete"
                    >
                      <i className="icon-delete" />
                    </button>
                  </div>
                  <div className="clearfix" />
                </div>
              ))}
            {generatingDistractors && (
              <div className="doc-checking-msg d-flex align-items-center justify-content-center clr-black">
                <span className="doc-loader spinner-width-20 mr-2"></span>
                {t("Generating Distractors... Please wait")}
              </div>
            )}
            <IconLabelButton
              showIcon
              iconClass="icon-add"
              buttonLabel={t("ADD AN OPTION")}
              buttonClass="add-option-btn"
              onClick={addOption}
            />
          </div>
        )}
      </>
    );
  };

  const getTableDisplay = (
    data: any,
    answerKeySettings: any,
    noHeader: any
  ) => {
    let temp = typeof data === "string" ? JSON.parse(data) : data;
    if (answerKeySettings && answerKeySettings.enable_column_header) {
      temp &&
        temp.map((row: any, index: any) =>
          row.unshift(answerKeySettings.column_headers[index])
        );
    }

    return (
      <table className="quiz-table-answer-table">
        {!noHeader && (
          <thead>
            <tr>
              {answerKeySettings &&
                answerKeySettings.headers &&
                answerKeySettings.headers.map((column: any) => (
                  <th className="quiz-table-answer-cell">{column}</th>
                ))}
            </tr>
          </thead>
        )}
        <tbody>
          {temp &&
            temp.map((row: any) => (
              <tr>
                {row.map((column: any) => (
                  <td className="quiz-table-answer-cell">{column}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const divideArray = (data: any, controlColumnCount: any) => {
    // console.log('---------------------------');
    // console.log('data', data);
    // console.log('controlColumnCount', controlColumnCount);

    let temp = [...data];
    // console.log('temp.length / controlColumnCount', temp.length / controlColumnCount);
    // console.log('new Array(Math.ceil(temp.length))', new Array(Math.ceil(temp.length)));
    if (temp.length > 0) {
      const result = new Array(Math.ceil(temp.length / controlColumnCount))
        .fill(null)
        .map((_) => temp.splice(0, controlColumnCount));
      return result;
    }

    return [];
  };

  const getNewTableDisplay = (data: any = [], answerKeySettings: any = {}) => {
    return (
      <table className="quiz-table-answer-table">
        <thead>
          <tr>
            {answerKeySettings &&
              answerKeySettings.headers &&
              answerKeySettings.headers.map((column: any) => (
                <th className="quiz-table-answer-cell">{column}</th>
              ))}
          </tr>
        </thead>

        <tbody>
          {divideArray(data, answerKeySettings?.column_count).map(
            (row, index) => (
              <tr>
                <th className="quiz-table-header-cell">
                  {"column_headers" in answerKeySettings
                    ? answerKeySettings?.column_headers?.[index]
                    : ""}
                </th>
                {row.map((column) => (
                  <td className="quiz-table-answer-cell">{column.text}</td>
                ))}
              </tr>
            )
          )}
        </tbody>
      </table>
    );
  };

  const getMatchDisplay = (data: any) => {
    return (
      <table className="quiz-table-answer-table">
        <thead>
          <tr>
            {answerKeySettings &&
              answerKeySettings.headers &&
              answerKeySettings.headers.map((column: any) => (
                <th className="quiz-table-answer-cell">{column}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((row: any) => (
              <tr>
                <td className="quiz-table-answer-cell">{row.term}</td>
                <td className="quiz-table-answer-cell">{row.definition}</td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const getIdealAnswerItemsDisplay = () => {
    return (
      <ul>
        {idealAnswerItems.map((item: any) => (
          <li>{item.text}</li>
        ))}
      </ul>
    );
  };

  const getQuestionDisplay = () => {
    return (
      <React.Fragment>
        <div
          className="display-question"
          dangerouslySetInnerHTML={{
            __html: `Question ${questionNumber} ${questionText}`,
          }}
        ></div>
        {(questionType === "text" || questionType === "bullet") && (
          <div className="display-textinput">
            <div className="text-answer-field">
              {questionData.ideal_answer === ""
                ? t("Text answer field")
                : convertToMarkdown(idealAnswer)}
            </div>
          </div>
        )}
        {questionType === "newbullet" && (
          <div className="display-description">
            {questionData.ideal_answer_items.length === 0
              ? t("Bullet answer field")
              : getIdealAnswerItemsDisplay()}
          </div>
        )}
        {(questionType === "radio" || questionType === "checkbox") && (
          <div className="display-options">
            {questionOptions.map((option, index) => (
              <div key={option.id} className="option-display-field">
                {questionType === "radio" && <span className="radio-circle" />}
                {questionType === "checkbox" && (
                  <span className="checkbox-square" />
                )}
                <span dangerouslySetInnerHTML={{ __html: option.text }}></span>
              </div>
            ))}
          </div>
        )}

        {questionType === "description" && (
          <div className="display-description">
            {getTableDisplay(
              questionData.ideal_answer,
              questionData.answer_key_settings,
              true
            )}
          </div>
        )}

        {questionType === "table" && (
          <div className="display-description">
            {getTableDisplay(
              questionData.ideal_answer,
              questionData.answer_key_settings,
              null
            )}
          </div>
        )}

        {questionType === "essay" && (
          <div className="display-description">
            {getNewTableDisplay(
              questionData.ideal_answer_items,
              questionData.answer_key_settings
            )}
          </div>
        )}

        {questionType === "matching" && (
          <div className="display-description">
            {getMatchDisplay(questionData.matches)}
          </div>
        )}
        <div className="clearfix" />
      </React.Fragment>
    );
  };

  const removeTag = async (e: any, tag: string, tags: any[]) => {
    e.preventDefault();
    const filterTags = tags.filter((t) => t !== tag);
    setAllTags(filterTags);
    try {
      await dispatch(updateKeywordsOfQuestion(questionData.id, filterTags));
    } catch (e: any) {}
  };

  const genKeywords = async () => {
    const res: any = await dispatch(
      getQuizQuestionBankGenerateTags(questionId)
    );
    if (res.quiz_question.tags.length > 0) {
      setAllTags(res.quiz_question.tags);
    }
  };

  const linkSections = async () => {
    const res: any = await dispatch(
      questionBankLinkDocumentSections(questionId)
    );
    if (res?.quiz_question?.document_section_ids.length > 0) {
      setDocSections(res.quiz_question.document_section_ids);
    }
  };

  const renderTagging = () => {
    return (
      <>
        <div className="mt-3">
          <div className="d-flex flex-wrap justify-content-between align-items-start mb-2">
            <div>
              <h2 className="font-12 mb-1">
                {showKeywordsOptions ? t("Edit keywords") : t("keywords")}
              </h2>
              {showKeywordsOptions && (
                <p className="mb-0">
                  {t("Manage keywords for the knowledge.")}
                </p>
              )}
            </div>
            {showKeywordsOptions && (
              <div className="d-inline-block position-relative">
                {showConfirmGenerateKeys && (
                  <>
                    <div className="confirm-generate-keywords">
                      <div>
                        <b>{t("Generate keywords")}</b>
                      </div>
                      <div>
                        {t(
                          "Generating new keywords would replace existing keywords and remove existing feedback & tutoring mapping. Procceed?"
                        )}
                      </div>
                      <div className="d-flex my-2" style={{ float: "right" }}>
                        <button
                          className="btn btn-outline-primary font-sm font-600 py-1 mx-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowConfirmGenerateKeys(false);
                          }}
                        >
                          {t("Cancel")}
                        </button>
                        <button
                          className="btn btn-primary font-sm font-600 py-1 mx-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            genKeywords();
                            setShowConfirmGenerateKeys(false);
                          }}
                        >
                          {t("Proceed")}
                        </button>
                      </div>
                    </div>
                    <div className="right-triangle"></div>
                  </>
                )}
                <button
                  className="btn font-12 py-0 clr-primary px-1 font-weight-bold"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setShowConfirmGenerateKeys(true);
                  }}
                >
                  {t("Generate Keywords")}
                </button>
              </div>
            )}
          </div>

          <div className="d-flex align-items-start">
            <TagsComponent
              row={{ tags: [...allTags] }}
              showAllTagsAtOnce
              hideDeleteOption={!showKeywordsOptions}
              removeTagHandler={(e: any, value: any) => {
                removeTag(e, value, allTags);
              }}
            />
            {showKeywordsOptions && (
              <button
                className="btn btn-add-tag font-12 my-1"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setShowTagsModal(!showTagsModal);
                }}
              >
                {t("+ Add Tag")}
              </button>
            )}
          </div>
        </div>

        {showKeywordsOptions && (
          <div className="mt-1">
            <div className="d-flex flex-wrap justify-content-between align-items-start mb-2">
              <div>
                <h2 className="font-12 mb-1">{t("Curriculum Tagging")}</h2>
                <p className="mb-0">
                  {t("Tag Quiz Question to document sections")}
                </p>
              </div>

              <button
                className="btn font-12 py-0 clr-primary px-1 font-weight-bold"
                onClick={(e: any) => {
                  e.stopPropagation();
                  linkSections();
                }}
              >
                {t("Generate matches")}
              </button>
            </div>
            <div className="font-12 tagg-info-msg py-1 px-3 my-2 d-flex align-items-center">
              <img src={tagInfoicon} alt="" className="mr-3" />
              Please ensure to assign the correct keywords for better
              suggestions
            </div>
            <div
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <QuizzesDocumentGroup
                allDocuments={allDocuments}
                documentId={questionId}
                question={{
                  ...questionData,
                  document_sections: docSections,
                }}
                addSectionSuccessCb={(docSections: any) => {
                  setDocSections(docSections);
                  console.log({ docSections });
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const confirmedTagsHandler = async (e: any, tags: any[]) => {
    e.preventDefault();
    setShowTagsModal(false);
    const tagsValues: string[] = tags.map((tag) => tag.value);
    setAllTags(tagsValues);
    try {
      await dispatch(updateKeywordsOfQuestion(questionData.id, tagsValues));
    } catch (e: any) {}
  };

  if (forDisplay) {
    return (
      <div>
        {getQuestionDisplay()}
        {renderTagging()}
        <AddTags
          onHide={() => setShowTagsModal(!showTagsModal)}
          show={showTagsModal}
          confirmhandler={confirmedTagsHandler}
          selectedTags={
            allTags.map((t: any) => {
              return {
                value: t,
                label: t,
              };
            }) || []
          }
        />
      </div>
    );
  }

  return (
    <div
      className={
        markRed
          ? "subquestion-wrapper error-sub-question"
          : "subquestion-wrapper"
      }
    >
      <div>
        <Accordion
          title={`Question ${questionNumber}`}
          defaultActiveKey={acctiveAcc}
          onSelect={(id: any) => setActiveAcc(id)}
        >
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            className="toggle-question"
          >
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <div className="sub-question-description">
                <b>{`Question ${questionNumber}`}</b>
              </div>
              <Image
                src={chevronRight}
                className={cx({ "chev expanded": acctiveAcc == "0" })}
              />
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <>
              {getQuestionHTML()}
              {<div className="clearfix" />}
              {getOptionsHTML()}
              {scoredBy.value === "keywords" && (
                <div className="flex-one">
                  <h3 className="card-subheading">
                    {t(
                      "Keywords (user answers will be scored against these keywords only)"
                    )}
                  </h3>
                  <TableEditor
                    disableHeader
                    disableColumnAdd
                    onTableChange={onKeywordsTableChange}
                    tableValues={keywordsData}
                    headerValues={["Keyword", "Score"]}
                    enableColumnHeader={false}
                  />
                </div>
              )}
              {questionType !== "group" && <div className="clearfix" />}
              {markRed && <div className="card-error">{errorMessage}</div>}
            </>
          </Accordion.Collapse>
        </Accordion>
      </div>
    </div>
  );
};

export default Question;
