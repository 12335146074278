import { useEffect, useState } from "react";
import { Accordion, Card, Col, Row, useAccordionToggle } from "react-bootstrap";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getLearningUserDetail,
  resendInvite,
} from "../../../store/knowledge/actions";
import { getCommonState } from "../../../store/common/selector";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import ForgotPasswordModal from "../../../routes/Users/partials/ForgotPasswordModal";

import { useTranslation } from "react-i18next";

function AdminLearnerTile({
  user,
  editAdminToggleHandler,
  deleteAdminHandler,
  classMode,
  isAdmin,
  isLearner,
  addToSelectedRows,
  selectedNodes,
}: any) {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const commonState = useSelector(getCommonState);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showsResendInviteModal, setShowsResendInviteModal] = useState(false);
  const [showsForgotPasswordModal, setShowsForgotPasswordModal] =
    useState(false);
  const isSuperadmin = commonState && commonState?.user?.role === "superadmin";

  useEffect(() => {
    setUserDetails(user);
  }, [user]);

  const ContextAwareToggle = ({
    children,
    eventKey,
    callback,
    is_suggested,
    _id,
  }: any) => {
    const decoratedOnClick = useAccordionToggle(eventKey, async () => {
      if (userDetails && !userDetails.userClasses) {
        const response: any = await dispatch(getLearningUserDetail(eventKey));
        setUserDetails({
          ...userDetails,
          userClasses: response,
        });
      }
    });
    return (
      <>
        {addToSelectedRows && (
          <input
            type="checkbox"
            className="mr-2"
            onChange={(e) => {
              addToSelectedRows(e, _id);
            }}
            checked={selectedNodes.indexOf(eventKey) >= 0}
          />
        )}
        <span
          className={`${is_suggested ? "is_suggested" : ""} ${
            addToSelectedRows ? "" : "pl-3"
          } py-2`}
          onClick={decoratedOnClick}
        >
          {children}
        </span>
      </>
    );
  };

  const resendInviteHandler = async (user: any) => {
    try {
      setSelectedUser(user);
      setShowsResendInviteModal(true);
    } catch (error: any) {}
  };

  const forgotPasswordHandler = async (user: any) => {
    try {
      setSelectedUser(user);
      setShowsForgotPasswordModal(true);
    } catch (error: any) {}
  };

  const confirmResendInviteHandler = async (
    e: React.ChangeEvent<HTMLInputElement>,
    selectedRow: any,
  ) => {
    e.preventDefault();
    setShowsResendInviteModal(false);
    await dispatch(resendInvite(selectedRow._id));
  };

  if (!userDetails) {
    return <></>;
  }

  return (
    <>
      <ConfirmationModal
        message={t("Are you sure want to resend the invite?")}
        size={"md"}
        show={showsResendInviteModal}
        selectedRow={selectedUser}
        onHide={() => setShowsResendInviteModal(false)}
        confirmHandler={confirmResendInviteHandler}
        buttonText={t("Resend Invite")}
      />
      {isSuperadmin && (
        <ForgotPasswordModal
          user_id={selectedUser?._id}
          show={showsForgotPasswordModal}
          size={"lg"}
          onHide={() => setShowsForgotPasswordModal(false)}
        />
      )}

      <Card>
        <Card.Header>
          <ContextAwareToggle
            // is_suggested={knowledge.is_suggested}
            _id={userDetails._id}
            eventKey={userDetails._id}
          >
            <Row>
              <Col className="col-lg-3 col-6">
                <small className="font-sm">{t("First Name")}</small>
                <div>{userDetails.first_name}</div>
              </Col>
              <Col className="col-lg-2 col-6">
                <small className="font-sm">{t("Last Name")}</small>
                <div>{userDetails.last_name}</div>
              </Col>
              <Col className="col-lg-5 col-6">
                <small className="font-sm">{t("Email")}</small>
                <div>{userDetails.email}</div>
              </Col>
              <Col className="col-lg-2 col-6">
                <small className="font-sm">{t("Status")}</small>
                {userDetails.status === "active" && (
                  <div className="clr-green">{t("Active")}</div>
                )}
                {userDetails.status === "inactive" && (
                  <div className="clr-orange">{t("Pending Invite")}</div>
                )}
              </Col>
            </Row>
          </ContextAwareToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={userDetails._id}>
          <Card.Body>
            <Row>
              {!isLearner && (
                <>
                  {!isAdmin && (
                    <>
                      <Col lg={3} sm={6} className="mb-3">
                        <small className="font-sm mb-1 d-inline-block">
                          {t("First Name")}
                        </small>
                        <div className="font-600 font-16">
                          {userDetails.first_name}
                        </div>
                      </Col>
                      <Col lg={3} sm={6} className="mb-3">
                        <small className="font-sm mb-1 d-inline-block">
                          {"Last Name"}
                        </small>
                        <div className="font-600 font-16">
                          {userDetails.last_name}
                        </div>
                      </Col>
                    </>
                  )}
                  <Col lg={3} sm={6} className="mb-3">
                    <small className="font-sm mb-1 d-inline-block">
                      {t("Permission")}
                    </small>
                    <div className="font-600 font-16">
                      {userDetails.role === "readonlyadmin"
                        ? t("View Only")
                        : userDetails.role === "admin" ||
                            userDetails.is_superadmin
                          ? t("Can Edit")
                          : ""}
                    </div>
                  </Col>
                  <Col lg={3} sm={6} className="mb-3">
                    <small className="font-sm mb-1 d-inline-block">
                      {t("Last Activity (DD/MM/YYYY)")}
                    </small>
                    <div className="font-600 font-16">
                      {userDetails.userClasses &&
                        userDetails.userClasses.last_login_date && (
                          <Moment format="DD/MM/YYYY">
                            {userDetails.userClasses.last_login_date}
                          </Moment>
                        )}
                    </div>
                  </Col>
                </>
              )}

              {!isAdmin && (
                <>
                  <Col md={6} className="mb-3">
                    <small className="font-sm mb-1 d-inline-block">
                      {t("Classes")}
                    </small>
                    <div className="key-tags d-flex flex-wrap align-items-center">
                      {userDetails.userClasses &&
                        userDetails.userClasses.classes &&
                        userDetails.userClasses.classes.map((classes: any) => {
                          return (
                            <div className="bg-none">
                              <span>{classes?.name}</span>
                            </div>
                          );
                        })}
                    </div>
                  </Col>
                  <Col md={6} className="mb-3">
                    <small className="font-sm mb-1 d-inline-block">
                      {t("Learner Groups")}
                    </small>
                    <div className="key-tags d-flex flex-wrap align-items-center">
                      {userDetails.userClasses &&
                        userDetails.userClasses.groups &&
                        userDetails.userClasses.groups.map((classes: any) => {
                          return (
                            <div className="bg-none">
                              <span>{classes?.name}</span>
                            </div>
                          );
                        })}
                    </div>
                  </Col>
                </>
              )}

              {isLearner && (
                <Col md={6} className="mb-3">
                  <small className="font-sm mb-1 d-inline-block">
                    {t("Last Activity (DD/MM/YYYY)")}
                  </small>
                  <div className="font-600 font-16">
                    {userDetails.userClasses &&
                      userDetails.userClasses?.last_active_at && (
                        <Moment format="DD/MM/YYYY">
                          {userDetails.userClasses.last_active_at}
                        </Moment>
                      )}
                  </div>
                </Col>
              )}
            </Row>

            <div className="mt-3 text-right">
              {!classMode && (
                <button
                  className="btn btn-outline-primary font-weight-normal mx-2 py-1 py-md-2"
                  onClick={() => editAdminToggleHandler(userDetails, true)}
                >
                  {t("Edit")}
                </button>
              )}
              {userDetails.status === "active" && isSuperadmin && (
                <button
                  className="btn btn-outline-primary font-weight-normal mx-2 py-1 py-md-2"
                  onClick={() => forgotPasswordHandler(userDetails)}
                >
                  {t("Forgot Password")}
                </button>
              )}
              {userDetails.status === "inactive" && (
                <button
                  className="btn btn-outline-primary font-weight-normal mx-2 py-1 py-md-2"
                  onClick={() => resendInviteHandler(userDetails)}
                >
                  {t("Resend Invite")}
                </button>
              )}
              <button
                className="btn btn-outline-danger font-weight-normal mx-2 py-1 py-md-2"
                onClick={() =>
                  deleteAdminHandler(userDetails._id, userDetails.role)
                }
              >
                {t("Remove from agent")}
              </button>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
}

export default AdminLearnerTile;
