import {
  Button,
  Image,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import closeimg from "../../../assets/images/close-blk.svg";

type IntegrationHeaderData = {
  modalTitle: string;
  closeModal?: any;
}

const IntegrationModalHeader = (props: IntegrationHeaderData) => {
  return (
      <ModalHeader className="justify-content-start border-bottom py-2 align-items-center">
        <ModalTitle className="container-fluid d-inline-flex justify-content-between align-items-center p-0">
          <div>
            {props.modalTitle}
          </div>
          <Button
              onClick={props?.closeModal ? props.closeModal : null}
          >
            <Image src={closeimg}/>
          </Button>
        </ModalTitle>
      </ModalHeader>
  );
};

export default IntegrationModalHeader;
