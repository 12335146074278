import {
  Button,
  Modal,
  ModalFooter,
} from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import {useTranslation} from "react-i18next";
import {FormData} from '../IntegrationType'
import IntegrationModalHeader from '../Modal/IntegrationModalHeader'
import CanvasConfigureForm from "./CanvasConfigureForm";

type ApiSetup = {
  integrationType: string;
  closeModal: () => void;
  renderCopy: (text: string) => JSX.Element;
  handleIntegration: () => Promise<void>;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>, name: string) => void;
}


const CanvasApiModal = (props: ApiSetup) => {
  const {t} = useTranslation("translation");

  return (
      <Modal
          className="modal-width"
          aria-labelledby="contained-modal-title-vcenter"
          show={true}
          onHide={() => {
          }}
          centered
      >
        <IntegrationModalHeader
            modalTitle="Enable Content Integration with Canvas"
            closeModal={props?.closeModal}
        />

        <ModalBody className="integration-modal-body">
          <div className="tw-flex  tw-gap-4 canvas-manual-integration">
            <div className="tw-px-4 tw-py-4 w-90 left-content-wrap">
              <h2 className="mb-2">{t("Step 1: Configure Developer Key in Canvas LMS")}</h2>
              <p
                className="mb-0 subhEad">{t("To integrate Noodle Factory with Canvas, you first need to create an API Key. Follow the steps below:")}</p>
              <ol>
                <li>{t("Log in to Canvas as an admin")}.</li>
                <li>{t("In Global Navigation, click on")}&nbsp;
                  <strong>Admin</strong> {t("link, then click the name of the account")}.
                </li>
                <li>{t("In Account Navigation, click on")} <strong>Developer Keys</strong> {t("link")}.</li>
                <li>{t("Click on")} <strong>+ Developer Key</strong> {t("button and select")} <strong>+ API
                  Key</strong> {t("from the dropdown")}. <br/>
                  {t("You'll need to complete the form with the following details")}:
                  <ul>
                    <li>
                      <span className="label">Key Name: </span>
                      {props.renderCopy("Noodle Factory Content Integration")}
                    </li>
                    <li>
                      <span className="label">Owner Email: </span>
                      &lt; {t("Key Owner's Email")} &gt;
                    </li>
                    <li>Redirect URIs:
                      <ul>
                        <li>{t("For Asia Pacific and Europe")}: {props.renderCopy("https://portalapi.noodlefactory.ai/api/v1/integration/canvas-integrations/callback")}
                        </li>
                        <li>{t("For	North America")}: {props.renderCopy("https://portalapius.noodlefactory.ai/api/v1/integration/canvas-integrations/callback")}
                        </li>
                      </ul>
                    </li>
                    <li>Client Credentials Audience: Canvas</li>
                  </ul>
                </li>
                <li>{t("If you want to enforce scopes for the keys, you can do so by enabling the toggle")} <em>Enforce
                  Scopes</em>. {t("Select the following scopes from the list")}:
                  <ul>
                    <li>url:GET|/api/v1/courses</li>
                    <li>url:GET|/api/v1/courses/:course_id/assignments</li>
                    <li>url:POST|/api/v1/courses/:course_id/assignments/:assignment_id/submissions/update_grades</li>
                    <li>url:GET|/api/v1/courses/:course_id/files</li>
                    <li>url:GET|/api/v1/courses/:course_id/files/:id</li>
                    <li>url:GET|/api/v1/courses/:course_id/pages</li>
                    <li>url:GET|/api/v1/courses/:course_id/pages/:url_or_id</li>
                    <li>url:GET|/api/v1/courses/:course_id/quizzes</li>
                    <li>url:GET|/api/v1/courses/:course_id/quizzes/:quiz_id/questions</li>
                    <li>url:GET|/api/v1/courses/:course_id/quizzes/:quiz_id/questions/:id</li>
                  </ul>
                </li>
                <li>{t("Click on")} <strong>Save</strong> {t("to create the API Key")}.</li>
                <li>
                  {t("Once the LTI Key is created, go back to the list of Developer Keys and enable the key by clicking on the toggle under column")} <strong>State</strong>.
                </li>
              </ol>
              <h2 className="mb-2">{t("Step 2: Retrieve API Key Details")}</h2>
              <p
                  className="mb-0 subhEad">{t("In Developer Keys listing, you will be able to find the necessary information to fill in the form on the right")}:</p>
              <ul>
                <li><em>Canvas LMS URL</em>: {t("This is the URL of your Canvas LMS instance")}</li>
                <li><em>Client ID</em>: {t("This is the number sequence found under the Details column")}</li>
                <li><em>Client Secret</em>: {t("Click on Show Key below the Client ID to reveal the Client Secret")}</li>
              </ul>
            </div>

            <CanvasConfigureForm
                integrateSpecificAgent={null}
                setTntegrateSpecificAgent={null}
                integrationType={props.integrationType}
                formData={props.formData}
                setFormData={props.setFormData}
                handleInputChange={props.handleInputChange}
                allAgents={null}
            />
          </div>
        </ModalBody>
        {/* {popUpState === "" && ( */}
        < ModalFooter>
          <Button
              variant="outline-primary"
              onClick={props.closeModal}
          >
            {t("Cancel")}
          </Button>
          <Button
              variant="primary"
              disabled={!props.formData.LMS_URL || !props.formData.Client_ID || !props.formData.Client_Secret}
              onClick={() => {
                props.handleIntegration();
                props.closeModal();
              }}
          >
            {t("Complete")}
          </Button>
        </ModalFooter>
        {/* )} */}
      </Modal>
  );
};
export default CanvasApiModal;