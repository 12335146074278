import { useTheme } from "@nivo/core";
import moment from "moment";

export const AxisLeftTick = (tick: any) => {
  const theme = useTheme();

  return (
    <g transform={`translate(${tick.x - 13},${tick.y})`} {...tick}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          ...theme.axis.ticks.text,
          fontFamily: '"Mulish", sans-serif',
          fill: "#333",
          fontSize: 11,
        }}
      >
        {tick.value}
        {tick.isQuestion ? "%" : ""}
      </text>
    </g>
  );
};

export const AxisBottomTick = (tick: any) => {
  const theme = useTheme();

  let tickValue = "";
  if (window.innerWidth < 991 && window.innerWidth > 640) {
    const formattedDate = moment(tick.value).format("DD-MM-YY");
    if (tick.tickIndex % 2 === 0) {
      tickValue = formattedDate;
    } else {
      tickValue = null;
    }
  } else if (window.innerWidth < 640) {
    const formattedDate = moment(tick.value).format("DD-MM");
    if (tick.tickIndex % 3 === 0) {
      tickValue = formattedDate;
    } else {
      tickValue = null;
    }
  } else {
    const formattedDate = moment(tick.value).format("DD-MM-YY");
    tickValue = formattedDate;
  }

  return (
    <g transform={`translate(${tick.x},${tick.y + 22}) rotate(${tick.rotate})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          ...theme.axis.ticks.text,
          fontFamily: '"Mulish", sans-serif',
          fill: "#333",
          fontSize: 11,
        }}
      >
        {tickValue}
      </text>
    </g>
  );
};

export const AxisBottomWithNoDateTick = (tick: any) => {
  const theme = useTheme();

  let tickValue = "";
  if (window.innerWidth < 991 && window.innerWidth > 640) {
    if (tick.tickIndex % 2 === 0) {
      tickValue = tick.value;
    } else {
      tickValue = null;
    }
  } else if (window.innerWidth < 640) {
    if (tick.tickIndex % 3 === 0) {
      tickValue = tick.value;
    } else {
      tickValue = null;
    }
  } else {
    tickValue = tick.value;
  }

  return (
    <g transform={`translate(${tick.x},${tick.y + 22}) rotate(${tick.rotate})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          ...theme.axis.ticks.text,
          fontFamily: '"Mulish", sans-serif',
          fill: "#333",
          fontSize: 11,
        }}
      >
        {tickValue}
      </text>
    </g>
  );
};

export const Tooltip = (tooltipData: any) => {
  const { indexValue, value } = tooltipData.data;
  const formattedDate = moment(indexValue).format("DD-MM-YYYY");
  return (
    <p
      className="tw-w-[100px] md:tw-w-[200px] lg:tw-w-[300px]"
      style={{
        backgroundColor: "#007bff",
        color: "#fff",
        padding: 10,
        margin: 0,
        borderRadius: 4,
      }}
    >
      Session: {formattedDate} - <b>{value}</b>
    </p>
  );
};

export const UsageTrendsTooltip = (tooltipData: any) => {
  const { indexValue, value, data } = tooltipData.data;
  const formattedDate = moment(indexValue).format("DD-MM-YYYY");
  return (
    <p
      className="tw-w-[100px] md:tw-w-[200px] lg:tw-w-[300px]"
      style={{
        backgroundColor: "#007bff",
        color: "#fff",
        padding: 10,
        margin: 0,
        borderRadius: 4,
      }}
    >
      Sessions: <b>{data.session_count}</b>
      <br />
      Users: <b>{data.user_count}</b>
    </p>
  );
};

export const QuizSubmissionToolTip = (tooltipData: any) => {
  const { indexValue, value } = tooltipData.data;
  const formattedDate = moment(indexValue).format("DD-MM-YYYY");
  return (
    <p className="tw-w-[100px] md:tw-w-[200px] lg:tw-w-[300px]"
      style={{
        backgroundColor: "#007bff",
        color: "#fff",
        padding: 10,
        margin: 0,
        borderRadius: 4,
      }}
    >
      Quiz Submission: {formattedDate} - <b>{value}</b>
    </p>
  );
};

export const QuestionTooltip = (tooltipData: any) => {
  const { indexValue, value } = tooltipData.data;
  const { question, name } = tooltipData.question;
  return (
    <p className="tw-w-[100px] md:tw-w-[200px] lg:tw-w-[300px]"
      style={{
        backgroundColor: "#007bff",
        color: "#fff",
        padding: 10,
        margin: 0,
        borderRadius: 4,
      }}
    >
      {name} - <b>{value}%</b>
    </p>
  );
};

export const QuizAnalyticsToolTip = (tooltipData: any) => {
  const { indexValue, data } = tooltipData.data;
  const { value, id } = data;
  return (
    <p className="tw-w-[100px] md:tw-w-[200px] lg:tw-w-[300px]"
      style={{
        backgroundColor: "#007bff",
        color: "#fff",
        padding: 10,
        margin: 0,
        borderRadius: 4,
      }}
    >
      {id} - <b>{value}</b>
    </p>
  );
};

export const CustomBarComponent = (props: any) => {
  let { x, y, width, height, showTooltip, hideTooltip, data } = props;
  const type = data.data.type;
  return (
    <g
      transform={`translate(${x}, ${y})`}
      // onAnimationEnd
      onMouseEnter={(event: any): void => {
        showTooltip(
          type === "quizsubmission" ? (
            <QuizSubmissionToolTip {...props} />
          ) : type === "QUESTION" ? (
            <QuestionTooltip {...props} question={data.data} />
          ) : type === "QUIZANALYTICS" ? (
            <QuizAnalyticsToolTip {...props} />
          ) : type === "usageTrends" ? (
            <UsageTrendsTooltip {...props} />
          ) : (
            <Tooltip {...props} />
          ),
          event
        );
      }}
      onMouseLeave={(event): void => {
        hideTooltip();
      }}
    >
      <rect
        width={width}
        height={height}
        rx="4"
        ry="4"
        fill="url(#gradientC)"
        strokeWidth="0"
        stroke="#0095ff"
      ></rect>
    </g>
  );
};
