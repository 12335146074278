import { Button, Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import { useTranslation } from "react-i18next";
import { FormData } from "../IntegrationType";
import IntegrationModalHeader from "../Modal/IntegrationModalHeader";
import TextInput from "../../../components/UI/OldFormBuilder/TextInput";

type ApiSetup = {
  integrationType: string;
  closeModal: () => void;
  renderCopy: (text: string) => JSX.Element;
  handleIntegration: () => Promise<void>;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => void;
};


const BlackboardApiModal = (props: ApiSetup) => {
  const { t } = useTranslation("translation");
  const windowObj: any = window;
  const blackboard_client_id =
      windowObj.ENVIRONMENT === "staging"
        ? "dc8083fc-8a31-479f-9a08-0c5386c82f93"
        : "d40489db-d007-41b8-a60a-1eb599192f9b";

  return (
    <Modal
      className="modal-width"
      aria-labelledby="contained-modal-title-vcenter"
      show={true}
      onHide={() => {}}
      centered
    >
      <IntegrationModalHeader
        modalTitle="Enable Content Integration with Blackboard"
        closeModal={props?.closeModal}
      />

      <ModalBody className="integration-modal-body">
        <div className="tw-flex  tw-gap-4 canvas-manual-integration">
          <div className="tw-px-4 tw-py-4 w-90 left-content-wrap">
            <h2 className="mb-2">{t("Configure REST API integration")}</h2>
            <ol>
              <li>{t("Log in to Blackboard as an admin")}.</li>
              <li>
                {t("Go to")} <strong>Administrator Panel</strong>.
              </li>
              <li>
                {t("On the Administrator Panel, under")}{" "}
                <strong>Integrations</strong>, {t("select")}{" "}
                <strong>REST API Integrations</strong>.
              </li>
              <li>
                {t("Click on button")} <strong>Create Integration</strong>,{" "}
                {t("and fill in the form as following")}
                <ul>
                  <li>
                    <span className="label">Application ID: </span>
                    {props.renderCopy(blackboard_client_id)}.
                  </li>
                  <li>
                    <span className="label">Learn User: </span>
                    {t("Click on")}&nbsp;
                    <strong>Browse</strong>&nbsp;
                    {t(
                      "and search for the Blackboard user that the integration should act as.",
                    )}
                  </li>
                  <li>
                    <span className="label">Available</span>&nbsp;({("some versions might have this field")}):
                    <em>Yes</em>
                  </li>
                  <li>
                    <span className="label">End User Access: </span>
                    <em>Yes</em>
                  </li>
                  <li>
                    <span className="label">Authorized To Act As User: </span>
                    <em>Service Default (No)</em>
                  </li>
                </ul>
              </li>
              <li>
                {t("Click on")} <strong>Submit</strong>,{" "}
                {t("to add the integration")}.
              </li>
              <li>
                {t(
                  "Fill out the form on the right using the correct URL for your Blackboard instance",
                )}
                .
              </li>
            </ol>
          </div>

          <div className="whit-box tw-px-4 right-content-wrap w-10">
            <div>
              <h2 className="mb-2">
                {t("Required fields for Noodle Factory")}
              </h2>
              <p className="mb-0">
                {t(
                  "As you complete each step, please fill up following values to set up the integration on Noodle Factory",
                )}
              </p>
              <form>
                <div className="flex-one mt-2">
                  <h3 className="mb-2">Blackboard URL</h3>
                  <TextInput
                    inputType="text"
                    placeholder="Blackboard URL"
                    autoFocus={false}
                    id="client-id-1"
                    value={props.formData?.LMS_URL}
                    inputChange={(e, name) => props.handleInputChange(e, name)}
                    max={undefined}
                    min={undefined}
                    tabIndex={undefined}
                    label=""
                    description=""
                    inputClass=""
                    name="LMS_URL"
                    disabled={false}
                    onKeyPress={() => {}}
                    useTextArea={false}
                    errorMessage=""
                    ref={undefined}
                    onPaste={undefined}
                    onFocus={undefined}
                    onBlur={undefined}
                    onKeyUp={undefined}
                    spellCheck={undefined}
                    required={undefined}
                    showError={undefined}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="outline-primary" onClick={props.closeModal}>
          {t("Cancel")}
        </Button>
        <Button
          variant="primary"
          disabled={!props.formData.LMS_URL}
          onClick={() => {
            props.handleIntegration();
            props.closeModal();
          }}
        >
          {t("Complete")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default BlackboardApiModal;
