import { Button, Form, Image, Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import { useTranslation } from "react-i18next";
import { FormData } from "../IntegrationType";
import IntegrationModalHeader from "../Modal/IntegrationModalHeader";
import { useState } from "react";
import TextInput from "../../../components/UI/OldFormBuilder/TextInput";
import Dropdown from "../../../components/UI/OldFormBuilder/Dropdown";

type LtiSetup = {
  integrationType: string;
  closeModal: () => void;
  integrateSpecificAgent: boolean;
  setTntegrateSpecificAgent: (value: boolean) => void;
  renderCopy: (text: string) => JSX.Element;
  data: LtiSetupData;
  handleIntegration: () => Promise<void>;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  allAgents: any[];
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => void;
};

type LtiSetupData = {
  redirect_uri: string;
  target_link_uri: string;
  openid_connect_initiation_url: string;
};

const BlackboardLtiModal = (props: LtiSetup) => {
  const windowObj: any = window;
  const blackboard_client_id =
    windowObj.ENVIRONMENT === "staging"
      ? "dc8083fc-8a31-479f-9a08-0c5386c82f93"
      : "d40489db-d007-41b8-a60a-1eb599192f9b";
  const { t } = useTranslation("translation");
  const [showsInstructionsModal, setShowsInstructionsModal] = useState(true);
  const [showsTestConnectModal, setShowsTestConnectModal] = useState(false);
  const closeModal = () => {
    setShowsInstructionsModal(false);
    setShowsTestConnectModal(false);
    props.closeModal();
  };
  const adminToolName = "Walter AI Admin";
  const studentToolName = "Walter AI Tutor";

  return (
    <>
      <Modal
        className="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
        show={showsInstructionsModal}
        onHide={() => {}}
        centered
      >
        <IntegrationModalHeader
          modalTitle="Add Walter AI Tutor to Blackboard"
          closeModal={closeModal}
        />

        <ModalBody className="integration-modal-body">
          <div className="tw-flex  tw-gap-4 canvas-manual-integration">
            <div className="tw-px-4 tw-py-4 w-90 left-content-wrap">
              <h2 className="mb-2">
                {t("Step 1: Add an LTI 1.3 Tool Provider")}
              </h2>
              <p className="mb-0 subhEad">
                {t(
                  "To add a new LTI 1.3 Tool Provider in Blackboard, please do the following",
                )}
                :
              </p>
              <ol>
                <li>{t("Log in to Blackboard as an admin")}.</li>
                <li>
                  {t("On the Administrator Panel, under")}{" "}
                  <strong>Integrations</strong>, {t("select")}{" "}
                  <strong>LTI Tool Providers</strong>.
                </li>
                <li>
                  {t("Select")} <strong>Register LTI 1.3/Advantage Tool</strong>
                  .
                </li>
                <li>
                  {t("In")} <strong>ENTER CLIENT ID</strong> {t("form")} &gt;{" "}
                  <strong>Client ID</strong> {t("input, enter:")}<br/>
                  {props.renderCopy(blackboard_client_id)}
                </li>
                <li>
                  {t("Click on")} <strong>Submit</strong>
                </li>
                <li>
                  {t("Under")} <strong>TOOL STATUS</strong>,&nbsp;
                  {t("some values are already prefilled. In")}{" "}
                  <strong>Tool Status</strong>, {t("tick on checkbox")}{" "}
                  <em>Approved</em>.
                </li>
                <li>
                  {t("Under")}{" "}
                  <strong>INSTITUTION POLICIES &gt; User Fields to Send</strong>
                  , {t("select all the checkboxes")}:
                  <ul>
                    <li>
                      <em>Role in Course</em>
                    </li>
                    <li>
                      <em>Name</em>
                    </li>
                    <li>
                      <em>Email Address</em>
                    </li>
                  </ul>
                </li>
                <li>
                  {t("Click on")} <strong>Submit</strong>{" "}
                  {t("to add the LTI 1.3 tool")}.
                </li>
              </ol>

              <h2 className="mb-2">{t("Step 2: Get deployment ID")}</h2>
              <ol>
                <li>
                  {t("Navigate to the")} <strong>LTI Tool Providers</strong>{" "}
                  {t("page")}.
                </li>
                <li>
                  {t("Hover over")} <em>Noodle Factory - Walter AI Tutor</em>
                  ,&nbsp;
                  {t("click on the arrow, and select")}&nbsp;
                  <strong>Manage Deployments</strong>.
                </li>
                <li>
                  {t("Fill in the form on the right")}
                  <ul>
                    <li>
                      <em>Blackboard URL</em>:&nbsp;
                      {t("This is the URL of your Blackboard instance")}
                    </li>
                    <li>
                      <em>Deployment ID</em>:{" "}
                      {t("Copy and paste the value of ")}&nbsp;
                      <strong>Default deployment ID</strong> {t("from")}{" "}
                      <strong>Manage Deployments</strong> {t("page in")}{" "}
                      Blackboard
                    </li>
                  </ul>
                </li>
              </ol>
            </div>

            <div className="whit-box tw-px-4 right-content-wrap w-10">
              <div>
                <h2 className="mb-2">
                  {t("Required fields for Noodle Factory")}
                </h2>
                <p className="mb-0">
                  {t(
                    "As you complete each step, please fill up following values to set up the integration on Noodle Factory",
                  )}
                </p>
                <form>
                  <div className="flex-one mt-2">
                    <h3 className="mb-2">Blackboard URL</h3>
                    <TextInput
                      inputType="text"
                      placeholder="Blackboard URL"
                      autoFocus={false}
                      id="client-id-1"
                      value={props.formData?.LMS_URL}
                      inputChange={(e, name) =>
                        props.handleInputChange(e, name)
                      }
                      max={undefined}
                      min={undefined}
                      tabIndex={undefined}
                      label=""
                      description=""
                      inputClass=""
                      name="LMS_URL"
                      disabled={false}
                      onKeyPress={() => {}}
                      useTextArea={false}
                      errorMessage=""
                      ref={undefined}
                      onPaste={undefined}
                      onFocus={undefined}
                      onBlur={undefined}
                      onKeyUp={undefined}
                      spellCheck={undefined}
                      required={undefined}
                      showError={undefined}
                    />
                  </div>
                  <div className="flex-one mt-2">
                    <h3 className="mb-2">{t("Deployment ID")}</h3>
                    <TextInput
                      inputType="text"
                      placeholder={t("Deployment ID")}
                      autoFocus={false}
                      id="client-id-3"
                      value={props.formData?.Deployment_ID}
                      inputChange={(e, name) =>
                        props.handleInputChange(e, name)
                      }
                      max={undefined}
                      min={undefined}
                      tabIndex={undefined}
                      label=""
                      description=""
                      inputClass=""
                      name="Deployment_ID"
                      disabled={false}
                      onKeyPress={() => {}}
                      useTextArea={false}
                      errorMessage=""
                      ref={undefined}
                      onPaste={undefined}
                      onFocus={undefined}
                      onBlur={undefined}
                      onKeyUp={undefined}
                      spellCheck={undefined}
                      required={undefined}
                      showError={undefined}
                    />
                  </div>
                </form>
              </div>
              <div className="fOrm-box">
                <div className="tOggle-box">
                  <div className="text mt-2">
                    <h3>{t("Integrate to Specific Agent")}</h3>
                  </div>
                  <button className="icon-button">
                    <Form.Check // prettier-ignore
                      className="custom-toggle font-weight-bold font-14 text-nowrap mt-1"
                      type="switch"
                      id="toggle-skip-summary"
                      // label="Skip Summary Review"
                      defaultChecked={props.integrateSpecificAgent}
                      onChange={() =>
                        props.setTntegrateSpecificAgent(
                          !props.integrateSpecificAgent,
                        )
                      }
                      value={String(props.integrateSpecificAgent)}
                    />
                  </button>
                </div>
                {props.integrateSpecificAgent && (
                  <div className="flex-one mt-2">
                    <Dropdown
                      placeHolder={`${t("Select Agent")}`}
                      label=""
                      defaultValue={undefined}
                      description=""
                      dropdownClassNamePrefix=""
                      isClearable={false}
                      isSearchable={false}
                      data={props.allAgents}
                      dropdownContainerClassName=""
                      onChange={(data: { value: string }) =>
                        props.setFormData({
                          ...props.formData,
                          Agent_ID: data?.value,
                        })
                      }
                      isMandatory={false}
                      value={undefined}
                      isDisabled={false}
                      menuPosition="bottom"
                      isMulti={undefined}
                      customStyles={undefined}
                      isNested={undefined}
                      onInputChange={() => {}}
                      isCreatable={undefined}
                      onCreateOption={undefined}
                      menuIsOpen={undefined}
                    />
                  </div>
                )}
                <p>
                  {t(
                    "Only enable this if you are using a single agent for all courses",
                  )}
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline-primary" onClick={closeModal}>
            {t("Cancel")}
          </Button>
          <Button
            variant="primary"
            disabled={!props.formData.LMS_URL || !props.formData.Deployment_ID}
            onClick={() => {
              props.handleIntegration();
              setShowsInstructionsModal(false);
              setShowsTestConnectModal(true);
            }}
          >
            {t("Next Step")}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        className="modal-width max-content"
        aria-labelledby="contained-modal-title-vcenter"
        show={showsTestConnectModal}
        onHide={() => {}}
        centered
      >
        <IntegrationModalHeader
          modalTitle="Enable Walter AI Tutor in a course"
          closeModal={props?.closeModal}
        />

        <ModalBody className="integration-modal-body">
          <div className="tw-px-4 tw-py-4 w-100">
            <div className="content-wrap-linkadmin">
              <p className="mb-0 subhEad">
                {t(
                  "After previous steps, the tool will appear to teachers in a course page. It can be found under",
                )}{" "}
                <strong>Course tools &gt; {adminToolName}</strong>.{" "}
                {t("To enable it for students, follow the instructions below")}:
              </p>
              <ol>
                <li className="mb-2">{t("Navigate to a course page")}.</li>
                <li className="mb-2">{t("Turn on Edit Mode")}.</li>
                <li className="mb-2">
                  {t("Click on")} <strong>Content</strong>&nbsp;
                  {t("in the left sidebar")}.
                </li>
                <li className="mb-2">
                  {t("Hover over")} <strong>Build Content</strong>&nbsp;
                  {t("dropdown, and select")}&nbsp;
                  <strong>{studentToolName}</strong>.
                </li>
                <li className="mb-2">
                  {t("Set the name for the content, for example set name as")}
                  &nbsp;
                  <strong>{studentToolName}</strong>.
                </li>
                <li className="mb-2">
                  {t("Click on")} <strong>Submit</strong>&nbsp;
                  {t("to complete adding the tool to the course.")}
                </li>
                <li className="mb-2">
                  {t(
                    "After that, you can try the newly setup tool in the course",
                  )}
                </li>
              </ol>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={closeModal}>
            {t("Complete")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default BlackboardLtiModal;
