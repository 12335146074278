import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { useDispatch, useStore } from "react-redux";
import {
  getAllQuizSubmissions,
  updateSubmissionsAcknowledge,
} from "../../../store/quizzes/actions";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { getClasses } from "../../../store/dashboard/actions";
import { Button, ButtonGroup } from "react-bootstrap";
import QuizSumbmissionDetails from "./QuizSubmissionDetails";
import { useTranslation } from "react-i18next";
import { QUIZZSUBMISSIONBYSUBMISSIONID } from "../../../config";
import { DashboardFilters } from "../../../components/UI/Dashboard";
import {
  HelperService,
  getAllFormattedSubmissions,
} from "../../../services/helper.service";
import moment from "moment";
import DateRangePickerComponent from "../../../components/UI/DateRangePicker/DateRangePicker";
import downarrowblue from "../../../assets/New-images/dropdown-arow-blue.svg";
import downarrow from "../../../assets/New-images/button-down-grey.svg";
import uparrow from "../../../assets/New-images/button-right-grey.svg";
import ExportSubmissionsHandler, { customStyles, submissionsCustomStyles } from "./QuizSubmissionUtils";
import { getNewTableDisplay } from "../QuizQuestionEditor/data";
import Pagination from "../../../components/UI/Pagination/Pagination";
import QuestionListSkeleton from "../../../components/UI/Skeletons/QuestionListSkeleton/QuestionListSkeletons";
import ScrollButtons from "../../../components/UI/ScrollButtons/ScrollButtons";

// import QuizSumbmissionDetails from "./quizSubmissionDetails";
// import IconLabelButton from "../../../components/IconLabelButton";
// import Checkbox from "../../../components/Checkbox";
// import Dropdown from "../../../components/Dropdown";

const QuizSubmissionList = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const helperService = new HelperService();
  const [channels, setChannels] = useState([]);
  const [quizSubmissions, setQuizSubmissions] = useState([]);
  const [showSubmissionDetailScreen, setShowSubmissionDetailsScreen] =
    useState(false);
  const [authorEmail, setAuthorEmail] = useState("");
  const [submissionId, setSubmissionId] = useState("");
  const [pending, setPending] = useState(false);
  const [classDropdownData, setClassDropdownData] = useState<any[]>([]);
  const [selectedClass, setSelectedClass] = useState<any>({
    value: null,
    label: "All Classes",
    connectionIds: null,
  });
  const [connectionIdClassMap, setConnectionIdClassMap] = useState<any>({});
  const [filtersData, setFiltersData] = useState({
    connection_id: null,
    channel: null,
    to_date: null,
    from_date: null,
  });
  const [dateParameters, setDateParameters] = useState({
    value: null,
    label: t("All Time"),
  });
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [submissionsType, setSubmissionsType] = useState("submissions");
  const [questionsData, setQuestionsData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationState, setPaginationState] = useState(null);
  const [paginatedrecords, setPaginatedRecords] = useState([]);

  useEffect(() => {
    const func = async () => {
      try {
        setPending(true);
        const { classes }: any = await dispatch(getClasses());
        await getClassesForUser(classes);
        setPending(false);
      } catch (error) {
        setPending(false);
      }
    };
    func();
  }, []);

  useEffect(() => {
    if (pending) {
      return;
    }
    getQuizSubmissions(
      selectedClass.connectionIds ? selectedClass.connectionIds : ""
    );
  }, [filtersData, selectedClass, submissionsType]);

  useEffect(() => {
    if (dateParameters.value === null) {
      setFiltersData({
        ...filtersData,
        to_date: null,
        from_date: null,
      });
      return;
    }
    if (dateParameters.value !== "custom") {
      setFiltersData({
        ...filtersData,
        to_date: moment(new Date()).format("YYYY-MM-DD"),
        from_date: moment(new Date())
          .subtract(dateParameters.value, "days")
          .format("YYYY-MM-DD"),
      });
    }
  }, [dateParameters.value]);

  useEffect(() => {
    const fun = async () => {
      setPending(true);
      await paginate(questionsData);
      setPending(false);
    };
    fun();
    return () => {};
  }, [currentPage, perPage]);

  const dateSort = (rowA: any, rowB: any) => {
    let temp =
      rowA.submittedOn > rowB.submittedOn
        ? 1
        : rowB.submittedOn > rowA.submittedOn
        ? -1
        : 0;
    return temp;
  };

  const learningQuizDetailsColumns = [
    {
      name: t("Learner Name"),
      selector: "name",
      sortable: true,
      grow: 2,
      style: {
        color: "#0923E6",
        fontWeight: "700",
        textDecoration: "underline",
      },
    },
    {
      name: t("Email/Session"),
      selector: "email",
      sortable: true,
      grow: 2,
    },
    {
      name: t("Submission Date"),
      selector: "submittedOn",
      sortable: true,
      wrap: true,
      sortFunction: dateSort,
      format: (row: any, index: any) => {
        return (
          <Moment format="DD/MM/YYYY HH:mm A" utc local>
            {row.submittedOn}
          </Moment>
        );
      },
      minWidth: "165px",
    },
    {
      name: t("Quiz Score"),
      selector: "maxScore",
      sortable: true,
      wrap: true,
      maxWidth: "110px",
    },
    {
      name: t("Actions"),
      selector: "action",
      wrap: true,
      maxWidth: "128px",
    },
  ];

  const submissionsColumns = [
    {
      name: t("#"),
      selector: "serial",
      sortable: false,
      maxWidth: "60px",
      minWidth: "60px",
    },
    {
      name: t("Question"),
      selector: (row: any) => row,
      sortable: false,
      cell: (row: any, index: any) => {
        return getHtmlDisplay("tbl-ques-col", row.text);
      },
    },
    {
      name: t("Learner Answer"),
      selector: "submission_count",
      sortable: false,
      cell: (row: any) => {
        let answerText = row.answer;
        return getHtmlDisplay("quiz-answer-tbl", answerText);
      },
    },
    {
      name: t("Feedback"),
      selector: "user_email",
      sortable: false,
      cell: (row: any) => {
        let answerText = row.recommendedAnswer;
        return row.type === "essay"
          ? getHtmlDisplay("quiz-answer-tbl", answerText)
          : "-";
      },
    },
    {
      name: t("Ideal Answer"),
      selector: "user_email",
      sortable: false,
      cell: (row: any) => {
        let answerText = row.recommendedAnswer;
        if (row.type === "newbullet") {
          answerText = getBulletNewDisplayString(
            row.ideal_answer_items.map((item: any) => item.text)
          );
        } else if (row.type === "bullet") {
          const trimmedArray = answerText
            .split("<br />")
            .map((item: string) => item.replace("- ", ""));
          answerText = getBulletNewDisplayString(trimmedArray);
        } else if (row.type === "table") {
          return (
            <div className="display-description">
              {getNewTableDisplay(
                row.ideal_answer_items,
                row.answer_key_settings
              )}
            </div>
          );
        } else if (row.type === "description") {
          return (
            <div className="display-description">
              {getTableDisplay(row.ideal_answer, row.answer_key_settings, true)}
            </div>
          );
        } else if (row.type === "matching") {
          return (
            <div className="display-description">
              {getMatchDisplay(row.matches, row.answer_key_settings)}
            </div>
          );
        } else if (row.type === "essay") {
          return getHtmlDisplay("", row.ideal_answer);
        }
        return getHtmlDisplay("quiz-answer-tbl", answerText);
      },
    },
    {
      name: t("Score"),
      selector: "last_score",
      sortable: false,
      maxWidth: "80px",
      cell: (row: any, index: any) => {
        return getHtmlDisplay("", row.formattedUserScore);
      },
    },
  ];

  const questionsColumns = [
    {
      name: t("Learner Name"),
      selector: "name",
      sortable: true,
      maxWidth: "240px",
      style: {
        color: "#0923E6",
        fontWeight: "700",
        textDecoration: "underline",
      },
    },
    {
      name: t("Learner Email"),
      selector: "email",
      sortable: true,
      maxWidth: "240px",
      style: {
        fontWeight: "600",
      },
    },
    {
      name: t("Class"),
      selector: "submissionClass",
      sortable: true,
      maxWidth: "160px",
    },
    {
      name: t("Submitted On"),
      selector: "submittedOn",
      sortable: true,
      wrap: true,
      sortFunction: (a: any, b: any) => {
        return b.submittedOn - a.submittedOn;
      },
      format: (row: any, index: any) => {
        return (
          <div>
            <Moment format="DD/MM/YYYY" utc local>
              {row.submittedOn}
            </Moment>
            <br />
            <Moment format="HH:mm" utc local>
              {row.submittedOn}
            </Moment>
          </div>
        );
      },
      width: "160px",
    },
    {
      name: t("Question"),
      selector: "text",
      sortable: true,
      cell: (row: any) => {
        return getHtmlDisplay("tbl-ques-col", row.text);
      },
      maxWidth: "320px",
    },
    {
      name: t("Learner Answer"),
      selector: "submission_count",
      sortable: false,
      cell: (row: any) => {
        let answerText = row.answer;
        return getHtmlDisplay("quiz-answer-tbl", answerText);
      },
      maxWidth: "320px",
    },
    {
      name: t("Score"),
      selector: "last_score",
      sortable: false,
      cell: (row: any, index: any) => {
        return getHtmlDisplay("", row.formattedUserScore);
      },
      maxWidth: "80px"
    },
    {
      name: t("Feedback"),
      selector: "user_email",
      sortable: false,
      cell: (row: any) => {
        let answerText = row.recommendedAnswer;
        return row.type === "essay" ? getHtmlDisplay("quiz-answer-tbl", answerText) : "-";
      },
      maxWidth: "320px",
    },
    {
      name: t("Ideal Answer"),
      selector: "user_email",
      sortable: false,
      maxWidth: "320px",
      cell: (row: any) => {
        let answerText = row.recommendedAnswer;
        if (row.type === "newbullet") {
          answerText = getBulletNewDisplayString(
            row.ideal_answer_items.map((item: any) => item.text)
          );
        } else if (row.type === "bullet") {
          const trimmedArray = answerText
            .split("<br />")
            .map((item: string) => item.replace("- ", ""));
          answerText = getBulletNewDisplayString(trimmedArray);
        } else if (row.type === "table") {
          return (
            <div className="display-description">
              {getNewTableDisplay(
                row.ideal_answer_items,
                row.answer_key_settings
              )}
            </div>
          );
        } else if (row.type === "description") {
          return (
            <div className="display-description">
              {getTableDisplay(row.ideal_answer, row.answer_key_settings, true)}
            </div>
          );
        } else if (row.type === "matching") {
          return (
            <div className="display-description">
              {getMatchDisplay(row.matches, row.answer_key_settings)}
            </div>
          );
        } else if (row.type === "essay") {
          return getHtmlDisplay("", row.ideal_answer);
        }
        return getHtmlDisplay("quiz-answer-tbl", answerText);
      },
    },
  ];

  const getBulletNewDisplayString = (data: any) => {
    let displayStr = "<ul>";
    displayStr += data
      .map((item: any) => {
        return `<li>${item}</li>`;
      })
      .join("");
    displayStr += "</ul>";
    return displayStr;
  };

  const getHtmlDisplay = (className: string, innerHtml: string) => {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{
          __html: innerHtml,
        }}
      ></div>
    );
  };

  const getTableDisplay = (
    data: any,
    answerKeySettings: any,
    noHeader: any
  ) => {
    let temp = typeof data === "string" ? JSON.parse(data) : data;
    if (answerKeySettings && answerKeySettings.enable_column_header) {
      temp &&
        temp.map((row: any, index: any) =>
          row.unshift(answerKeySettings.column_headers[index])
        );
    }
    return (
      <table className="quiz-table-answer-table">
        {!noHeader && (
          <thead>
            <tr>
              {answerKeySettings &&
                answerKeySettings.headers &&
                answerKeySettings.headers.map((column: any) => (
                  <th className="quiz-table-answer-cell">{column}</th>
                ))}
            </tr>
          </thead>
        )}
        <tbody>
          {temp &&
            temp.map((row: any) => (
              <tr>
                {row.map((column: any) => (
                  <td className="quiz-table-answer-cell">{column}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const getMatchDisplay = (data: any, answer_key_settings: any) => {
    return (
      <table className="quiz-table-answer-table">
        <thead>
          <tr>
            {answer_key_settings &&
              answer_key_settings.headers &&
              answer_key_settings.headers.map((column: any) => (
                <th className="quiz-table-answer-cell">{column}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((row: any) => (
              <tr>
                <td className="quiz-table-answer-cell">{row.term}</td>
                <td className="quiz-table-answer-cell">{row.definition}</td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const getQuizSubmissions = async (connectionIds: any) => {
    let quizId = props.quizId ? props.quizId : "";
    let questions: any[] = [];
    try {
      await setPending(true);
      const response: any = await dispatch(
        getAllQuizSubmissions(quizId, {
          ...filtersData,
          connection_id: selectedClass.connectionIds,
        })
      );
      if (response && response.quiz_submissions) {
        setQuizSubmissions(response.quiz_submissions);
        response.quiz_submissions.forEach((submission: any) => {
          if (
            submission.quiz &&
            submission.quiz.questions &&
            submission.quiz.questions.length > 0
          ) {
            const formattedQuestions = getAllFormattedSubmissions(submission);
            if(formattedQuestions && formattedQuestions.length > 0) {
              formattedQuestions.forEach((que: any) => {
                questions.push({
                  ...que,
                  email: submission.author_email
                    ? submission.author_email
                    : "User",
                  submittedOn: submission.created_at
                    ? submission.created_at
                    : "-",
                  submissionClass: Object.keys(connectionIdClassMap).includes(
                    submission.connection_id
                  )
                    ? connectionIdClassMap[submission.connection_id]
                    : "-",
                  name:
                    submission.author_first_name || submission.author_last_name
                      ? `${submission.author_first_name} ${submission.author_last_name}`
                      : "-",
                });
              })
            }
          }
        });
      }
      if (submissionsType === "questions") {
        await setQuestionsData(questions);
        paginate(questions);
      }
      await setPending(false);
    } catch (error) {
      await setPending(false);
      setQuizSubmissions([]);
    }
  };

  const getClassesForUser = (classes: any) => {
    setClassDropdownData(transformClassDropdownData(classes));
    let temp: any = {};
    classes.forEach((record: any) => {
      record.connections.forEach((connection: any) => {
        temp[connection.connection_id] = record.name;
      });
    });
    setConnectionIdClassMap(temp);
  };

  const transformClassDropdownData = (rawData: any) => {
    let temp: any[] = [
      {
        value: null,
        label: "All Classes",
        connectionIds: null,
      },
    ];
    rawData.forEach((record: any) => {
      temp.push({
        value: record._id,
        label: record.name,
        connectionIds: getConnectionIds(record),
      });
    });

    return temp;
  };

  const getConnectionIds = (classRecord: any) => {
    let connectionIds = "";
    if ("connections" in classRecord) {
      classRecord.connections.forEach((record: any) => {
        connectionIds = connectionIds + "," + record.connection_id;
      });
    }
    return connectionIds.substring(1);
  };

  const acknowlegeSubmission = async (
    quiz_id: any,
    submission_id: any,
    value: any
  ) => {
    try {
      setPending(true);
      await dispatch(
        updateSubmissionsAcknowledge(quiz_id, submission_id, {
          is_acknowledged: value,
        })
      );
      getQuizSubmissions(
        selectedClass.connectionIds ? selectedClass.connectionIds : ""
      );
      setPending(false);
    } catch (error) {}
  };

  const transformTableData = (data: any) => {
    function capitalizeFirstLetter(string: any) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    let details: any = [];
    let quizId = props.quizId ? props.quizId : "";
    data.forEach((prop: any, key: any) => {
      details.push({
        id: key,
        serial: key + 1,
        name:
          prop.author_first_name || prop.author_last_name
            ? `${prop.author_first_name} ${prop.author_last_name}`
            : "-",
        email: prop.author_email ? prop.author_email : "User",
        avgScore: prop.average_score
          ? parseFloat(prop.average_score).toFixed(2)
          : 0.0,
        // maxScore: Math.round((prop.score + Number.EPSILON) * 100) / 100,
        maxScore:
          props.decimalPlaces === "0"
            ? Math.floor(prop.score) + "/" + prop.quiz_score
            : parseFloat(prop.score).toFixed(props.decimalPlaces) +
              "/" +
              parseFloat(prop.quiz_score).toFixed(props.decimalPlaces),
        totalSubmissions: prop.submission_count,
        submittedFrom: prop.channel ? capitalizeFirstLetter(prop.channel) : "-",
        submittedOn: prop.created_at ? prop.created_at : "-",
        submissionClass:
          prop.connection_id in connectionIdClassMap
            ? connectionIdClassMap[prop.connection_id]
            : "-",
        acknowledge: (
          <>
            {" "}
            <div className="custom-checkbox mr-3 font-16">
              <input
                type="checkbox"
                className="mr-2"
                id="quiz-required"
                checked={prop.is_acknowledged}
                onChange={(e: any) => {
                  acknowlegeSubmission(quizId, prop._id, e.target.checked);
                }}
              />
              {/* <label htmlFor="quiz-required" className="mb-0 clr-grey-text">
                Mark quiz as required
              </label> */}
            </div>
          </>
        ),
        action: (
          <Button
            onClick={() => {
              setAuthorEmail(prop.author_email);
              setSubmissionId(prop._id);
              setShowSubmissionDetailsScreen(true);
              history.push(
                QUIZZSUBMISSIONBYSUBMISSIONID.replace(":quizId", quizId)
                  .replace(":submissionId", prop._id)
                  .replace(":email", prop.author_email)
              );
            }}
            variant="default"
            className="btn clr-primary font-sm font-600 p-0"
          >
            {t("Review Scores")}
          </Button>
        ),
        submissionId: prop._id,
      });
    });
    return details;
  };

  const handleClassFilterChange = (value: any) => {
    setSelectedClass(value);
    getQuizSubmissions(value.connectionIds);
  };

  const conditionalRowStyles = [
    {
      when: (row: any) => row,
      style: {
        borderLeft: "4px solid #f9b234",
      },
    },
  ];

  const hasFilterUpdated = async (e: any) => {
    await setFiltersData({
      ...filtersData,
      connection_id: e.connection_ids,
      channel: e.channel,
    });
  };

  const ExpandedComponent = ({ data, quizSubmissions }: any) => {
    return (
      <DataTable
        className="submissions-data white-space-pre-wrap"
        noContextMenu
        noHeader
        columns={submissionsColumns}
        data={getSubmissionsData(
          quizSubmissions.find((e: any) => e._id === data.submissionId)
        )}
        customStyles={submissionsCustomStyles}
      />
    );
  };

  const getSubmissionsData = (selectedRow: any) => {
    const rows = getAllFormattedSubmissions(selectedRow);
    if (rows && rows.length > 0) {
      return rows.map((row: any, key: number) => {
        return { ...row, serial: key + 1 };
      });
    }
  };

  const paginate = (matchedRecords: any[]) => {
    const paginationData = helperService.paginator(
      matchedRecords,
      currentPage,
      perPage
    );
    setPaginationState({
      ...paginationState,
      page: currentPage,
      page_size: perPage,
      total_items: matchedRecords.length,
      total_pages: paginationData.total_pages,
    });
    setPaginatedRecords([...paginationData.data]);
  };

  const handlePageChange = async (page: number) => {
    await setCurrentPage(page);
  };

  return (
    <div className="view-all-submissions">
      <div className="d-flex justify-content-between align-items-start mb-4">
        <div>
          <h5 className="mb-1 font-weight-bold">{t("View all Submission")}</h5>
          <div className="clr-grey font-600 font-16">
            {t("View all the submissions for the quiz by users.")}
          </div>
        </div>

        <ExportSubmissionsHandler
          exportName={`${props.quizName}-${selectedClass.label}-${moment(
            new Date()
          ).format("YYYY-MM-DD")}`}
          quizId={props?.quizId}
          classId={selectedClass.value}
          filters={filtersData}
        />
      </div>
      <div className="blue-filters d-flex flex-wrap justify-content-between align-items-center mb-2">
        <div className="d-flex flex-wrap">
          <Select
            className="select-sort select-target select-blue  shadow-none"
            classNamePrefix="select-target-prefix"
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => (
                <img className="mr-2" src={downarrowblue} alt="" />
              ),
            }}
            value={selectedClass}
            onChange={(newValue: any) => {
              setSelectedClass(newValue);
            }}
            options={classDropdownData}
            isSearchable={false}
          />
          <Select
            className="select-sort select-target select-blue shadow-none"
            classNamePrefix="select-target-prefix"
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => (
                <img className="mr-2" src={downarrowblue} alt="" />
              ),
            }}
            value={dateParameters ? { ...dateParameters } : null}
            onChange={(e: any) => {
              // setClickedCustomDatePickerValue(false);
              setDateParameters(e);
              if (e.value === "custom") {
                setOpenDatePicker(true);
              }
            }}
            options={[
              { value: null, label: t("All Time") },
              { value: "60", label: t("Last 60 days") },
              { value: "30", label: t("Last 30 days") },
              { value: "7", label: t("Last 7 days") },
              { value: "custom", label: t("Custom") },
            ]}
          />
          {openDatePicker && (
            <DateRangePickerComponent
              dateValue={{
                startDate:
                  filtersData && filtersData.from_date
                    ? new Date(filtersData.from_date)
                    : new Date(),
                endDate:
                  filtersData && filtersData.to_date
                    ? new Date(filtersData.to_date)
                    : new Date(),
              }}
              onDateChange={(e) => {
                setFiltersData({
                  ...filtersData,
                  from_date: e.filter_from_date,
                  to_date: e.filter_to_date,
                });
              }}
              onCloseDateRangeHandler={() => setOpenDatePicker(false)}
            />
          )}
          {/* <DashboardFilters hasFilterUpdated={hasFilterUpdated} hideDateOptions /> */}
        </div>
        <div className="d-flex align-items-center flex-wrap flex-sm-nowrap">
          <div className="font-weight-bold font-16 text-nowrap">
            {t("View By:")}
          </div>
          <ButtonGroup className="tab-btns-group w-100 ml-3">
            <Button
              variant="default"
              className={
                submissionsType === "submissions"
                  ? "btn-primary active"
                  : "btn-outline-primary"
              }
              onClick={() => {
                setSubmissionsType("submissions");
              }}
            >
              {t("Submissions")}
            </Button>
            <Button
              variant="default"
              className={
                submissionsType === "questions"
                  ? "btn-primary active"
                  : "btn-outline-primary"
              }
              onClick={() => {
                setSubmissionsType("questions");
              }}
            >
              {t("Questions")}
            </Button>
          </ButtonGroup>
        </div>
      </div>

      {submissionsType === "questions" && <ScrollButtons showScrollButtons={true} scrollWrapper="questions-data" />}

      {!showSubmissionDetailScreen && submissionsType === "submissions" && (
        <DataTable
          noHeader
          className="all-submissions"
          columns={learningQuizDetailsColumns}
          data={transformTableData(quizSubmissions)}
          customStyles={customStyles}
          expandableRows={true}
          expandableRowsComponent={
            <ExpandedComponent quizSubmissions={quizSubmissions} />
          }
          expandableIcon={{
            collapsed: <img src={uparrow} alt={t("collapsed")} />,
            expanded: <img src={downarrow} alt={t("expanded")} />,
          }}
          progressPending={pending} //
          progressComponent={
            <div style={{ padding: 10, width: "100%" }}>
              <QuestionListSkeleton count={12} />
            </div>
          }
          noContextMenu
          fixedHeader
          fixedHeaderScrollHeight="500px"
        />
      )}
      {!showSubmissionDetailScreen && submissionsType === "questions" && (
        <div>
          <DataTable
            noHeader
            columns={questionsColumns}
            className="questions-data white-space-pre-wrap"
            data={
              paginatedrecords && paginatedrecords.length > 0
                ? paginatedrecords
                : []
            }
            customStyles={customStyles}
            progressPending={pending}
            progressComponent={
              <div style={{ padding: 10, width: "100%" }}>
                <QuestionListSkeleton count={12} />
              </div>
            }
            noContextMenu
            fixedHeader
            fixedHeaderScrollHeight="500px"
          />
          {paginationState &&
            paginatedrecords &&
            paginatedrecords.length > 0 && (
              <Pagination
                currentPage={currentPage}
                pagination={{
                  ...paginationState,
                }}
                paginationPerPage={perPage}
                pageChange={(newPerPage: number, page: number) => {
                  setPerPage(newPerPage);
                  setCurrentPage(page);
                }}
                onChangePage={handlePageChange}
                hideSuggestedQuestion
              />
            )}
        </div>
      )}

      {showSubmissionDetailScreen && (
        <QuizSumbmissionDetails
          isForReview={false}
          quizId={props.quizId}
          authorEmail={authorEmail}
          submissionId={submissionId}
          onBack={() => setShowSubmissionDetailsScreen(false)}
          actionLabel={t("Update Scores")}
          decimalPlaces={props.decimalPlaces}
          connectionIdClassMap={connectionIdClassMap}
          enableLearning={props.enableLearning}
        />
      )}
    </div>
  );
};

export default QuizSubmissionList;
