import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Image, Modal, ModalFooter, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import cx from "classnames";

import closeimg from "../../../assets/images/close-blk.svg";
import { sendForgotPassword } from "../../../store/knowledge/actions";
import CopyIcon from "../../../components/UI/Common/CopyIcon";

type ForgotPasswordUser = {
  user_id: string;
  show: boolean;
  size?: "sm" | "lg" | "xl";
  onHide: () => void;
};

export default function ForgotPasswordModal(props: ForgotPasswordUser) {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [forgotPasswordUrl, setForgotPasswordUrl] = useState("");

  useEffect(() => {
    const getForgotPasswordUrl = async () => {
      const response: any = await dispatch(sendForgotPassword(props.user_id));
      if (response && response?.data?.reset_password_url) {
        setForgotPasswordUrl(response.data.reset_password_url);
      }
    };
    props.show && getForgotPasswordUrl();
  }, [props]);

  const sendForgotPasswordEmail = async (user_id: string) => {
    await dispatch(sendForgotPassword(props.user_id, true));
    props.onHide();
  };

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="delete-knowledge-confirmation"
    >
      <ModalHeader
        className={cx({
          "pt-3": true,
          "justify-content-between": true,
        })}
        onHide={props.onHide}
      >
        <ModalTitle id="contained-modal-title-vcenter">
          {t("Forgot Password")}
        </ModalTitle>
        <Button onClick={props.onHide}>
          <Image src={closeimg} />
        </Button>
      </ModalHeader>
      <ModalBody>
        <div className="mb-12">
          <h3 className="mb-1">{t("Link to reset password")}</h3>
          <div>
            <input
              type="text"
              className="form-control he-35 radius-2"
              name="forgot_password_url"
              value={forgotPasswordUrl}
              disabled={true}
            />
            <CopyIcon text={forgotPasswordUrl} />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="border-0 justify-content-center px-3 mb-3 mx-4 flex-nowrap">
        <button
          className="btn-outline-primary btn w-50 ml-0 mr-3"
          onClick={props.onHide}
        >
          {t("Cancel")}
        </button>
        <button
          className="btn-danger btn w-50 ml-3 mr-0"
          onClick={(e) => sendForgotPasswordEmail(props.user_id)}
        >
          {"Send Email"}
        </button>
      </ModalFooter>
    </Modal>
  );
}
