import {Button, Modal, ModalFooter, Image, Form} from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import { useTranslation } from "react-i18next";
import { FormData } from "../IntegrationType";
import IntegrationModalHeader from "../Modal/IntegrationModalHeader";
import { useState } from "react";
import TextInput from "../../../components/UI/OldFormBuilder/TextInput";
import Dropdown from "../../../components/UI/OldFormBuilder/Dropdown";

type LtiSetup = {
  integrationType: string;
  closeModal: () => void;
  integrateSpecificAgent: boolean;
  setTntegrateSpecificAgent: (value: boolean) => void;
  renderCopy: (text: string) => JSX.Element;
  data: LtiSetupData;
  handleIntegration: () => Promise<void>;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  allAgents: any[];
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => void;
};

type LtiSetupData = {
  redirect_uri: string;
  target_link_uri: string;
  openid_connect_initiation_url: string;
};

const Desire2learnLtiModal = (props: LtiSetup) => {
  const { t } = useTranslation("translation");
  const [showsInstructionsModal, setShowsInstructionsModal] = useState(true);
  const [showsTestConnectModal, setShowsTestConnectModal] = useState(false);
  const closeModal = () => {
    setShowsInstructionsModal(false);
    setShowsTestConnectModal(false);
    props.closeModal();
  };
  const toolNameTeacher = "Walter AI Admin";
  const toolName = "Walter AI";
  const linkName = `${toolName} - Basic Launch`;

  return (
    <>
      <Modal
        className="modal-width"
        aria-labelledby="contained-modal-title-vcenter"
        show={showsInstructionsModal}
        onHide={() => {}}
        centered
      >
        <IntegrationModalHeader
          modalTitle={`Enable ${toolName} in D2L Brightspace`}
          closeModal={closeModal}
        />

        <ModalBody className="integration-modal-body">
          <div className="tw-flex  tw-gap-4 canvas-manual-integration">
            <div className="tw-px-4 tw-py-4 w-90 left-content-wrap">
              <h2 className="mb-2">
                {t("Step 1: Register LTI  Advantage Tool i")}n D2L Brightspace
              </h2>
              <ol>
                <li>{t("Log in to Brightspace as admin role")}.</li>
                <li>
                  {t("Click on gear icon on top right corner, under")}&nbsp;
                  <strong>Organization Related</strong>, {t("select")}&nbsp;
                  <strong>Manage Extensibility</strong>
                </li>
                <li>
                  {"In"} <strong>Manage Extensibility</strong>{" "}
                  {t("page, click on tab")} <strong>LTI Advantage</strong>.
                </li>
                <li>
                  {t("Click on button")} <strong>Register Tool</strong>,&nbsp;
                  {t("then select")} <strong>Standard</strong>{" "}
                  {t("and fill in the form as following")}:
                  <ul>
                    <li>
                      <span className="label">Name: </span>&nbsp;
                      {props.renderCopy("Noodle Factory - Walter AI")}
                    </li>
                    <li>
                      <span className="label">Description: </span>&nbsp;
                      {props.renderCopy("Noodle Factory Walter AI")}
                    </li>
                    <li>
                      <span className="label">Domain: </span>&nbsp;
                      {props.renderCopy("https://oneauth.noodlefactory.ai")}
                    </li>
                    <li>
                      <span className="label">Redirect URLs: </span>&nbsp;
                      {props.renderCopy(props?.data?.redirect_uri)}
                    </li>
                    <li>
                      <span className="label">OpenID Connect Login URL: </span>
                      &nbsp;
                      {props.renderCopy(
                        props?.data?.openid_connect_initiation_url,
                      )}
                    </li>
                    <li>
                      <span className="label">Target Link URI: </span>&nbsp;
                      {props.renderCopy(props?.data?.target_link_uri)}
                    </li>
                    <li>
                      <span className="label">Keyset URL: </span>&nbsp;
                      {props.renderCopy(
                        "https://oneauth.noodlefactory.ai/auth/.well-known/jwks.json",
                      )}
                    </li>
                    <li>
                      <span className="label">Roles: </span>&nbsp;
                      {t("tick on")} <em>Send Institution Role</em>
                    </li>
                    <li>
                      {t("Click on button")} <strong>Register</strong>{" "}
                      {t("to submit the form")}.
                    </li>
                  </ul>
                </li>
                <li>
                  {t("Fill in the form on the right:")}
                  <ul>
                    <li>
                      <em>Brightspace LMS URL</em>:{" "}
                      {t("This is the URL of your D2L Brightspace instance")}
                    </li>
                    <li>
                      <em>Client ID</em>:{" "}
                      {t(
                        "Get the value of Client ID in the details of the app you just registered",
                      )}
                    </li>
                  </ul>
                </li>
              </ol>

              <h2 className="mb-2">
                {t("Step 2: Deploy the LTI  Advantage Tool in")} D2L Brightspace
              </h2>
              <ol>
                <li>{t("Log in to Brightspace as admin role")}.</li>
                <li>
                  {t("Click on gear icon on top right corner, under")}&nbsp;
                  <strong>Organization Related</strong>, {t("select")}&nbsp;
                  <strong>External Learning Tools</strong>
                </li>
                <li>
                  {t("In tab")} <strong>LTI Advantage</strong>,&nbsp;
                  {t("click on")} <strong>New Deployment</strong>,&nbsp;
                  {t("then complete the form as following")}:
                  <ul>
                    <li>
                      <span className="label">Tool: </span>&nbsp;
                      {t("select the newly added tool in Step 1")}
                    </li>
                    <li>
                      <span className="label">Name: </span>&nbsp;
                      {props.renderCopy(toolName)}
                    </li>
                    <li>
                      <span className="label">Description: </span>&nbsp;
                      {props.renderCopy(toolName)}
                    </li>
                    <li>
                      <span className="label">Security Settings: </span>&nbsp;
                      {t("tick on")} <em>Org Unit Information</em> {t("and")}{" "}
                      <em>User Information</em>.
                    </li>
                    <li>
                      <span className="label">Make tool available to: </span>
                      &nbsp;
                      {t("select Org units that you want to deploy the tool")}.
                    </li>
                    <li>
                      {t("Click on")} <strong>Create Deployment</strong>{" "}
                      {t("to submit for form")}
                    </li>
                  </ul>
                </li>
                <li>
                  {t(
                    "Once deployment is created, fill in the form on the right with the value of Deployment ID",
                  )}
                </li>
              </ol>

              <h2 className="mb-2">
                {t("Step 3: Create Link for Deployment")}
              </h2>
              <ol>
                <li>
                  {t("In the same")} <strong>External Learning Tools</strong>{" "}
                  {t("page, click on the Deployment just created in Step 2")}.
                </li>
                <li>
                  {t("Scroll to the bottom and click on")}{" "}
                  <strong>View Links</strong>.
                </li>
                <li>
                  {t("Click on")} <strong>New Link</strong>,&nbsp;
                  {t("then complete the form as following:")}
                  <ul>
                    <li>
                      <span className="label">Name: </span>&nbsp;
                      {props.renderCopy(linkName)}
                    </li>
                    <li>
                      <span className="label">URL: </span>&nbsp;
                      {props.renderCopy(props?.data?.target_link_uri)}
                    </li>
                    <li>
                      <span className="label">Type: </span>
                      <em>Basic Launch</em>.
                    </li>
                    <li>
                      {t("Click on")} <strong>Save and Close</strong>&nbsp;
                      {t("to submit for form")}.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>

            <div className="whit-box tw-px-4 right-content-wrap w-10">
              <div>
                <h2 className="mb-2">
                  {t("Required fields for Noodle Factory")}
                </h2>
                <p className="mb-0">
                  {t(
                    "As you complete each step, please fill up following values to set up the integration on Noodle Factory",
                  )}
                </p>
                <form>
                  <div className="flex-one mt-2">
                    <h3 className="mb-2">D2L Brightspace URL</h3>
                    <TextInput
                      inputType="text"
                      placeholder="D2L Brightspace URL"
                      autoFocus={false}
                      id="client-id-1"
                      value={props.formData?.LMS_URL}
                      inputChange={(e, name) =>
                        props.handleInputChange(e, name)
                      }
                      max={undefined}
                      min={undefined}
                      tabIndex={undefined}
                      label=""
                      description=""
                      inputClass=""
                      name="LMS_URL"
                      disabled={false}
                      onKeyPress={() => {}}
                      useTextArea={false}
                      errorMessage=""
                      ref={undefined}
                      onPaste={undefined}
                      onFocus={undefined}
                      onBlur={undefined}
                      onKeyUp={undefined}
                      spellCheck={undefined}
                      required={undefined}
                      showError={undefined}
                    />
                  </div>
                  <div className="flex-one mt-2">
                    <h3 className="mb-2">Client ID</h3>
                    <TextInput
                      inputType="text"
                      placeholder={t("Client ID")}
                      autoFocus={false}
                      id="client-id-2"
                      value={props.formData?.Client_ID}
                      inputChange={(e, name) =>
                        props.handleInputChange(e, name)
                      }
                      max={undefined}
                      min={undefined}
                      tabIndex={undefined}
                      label=""
                      description=""
                      inputClass=""
                      name="Client_ID"
                      disabled={false}
                      onKeyPress={() => {}}
                      useTextArea={false}
                      errorMessage=""
                      ref={undefined}
                      onPaste={undefined}
                      onFocus={undefined}
                      onBlur={undefined}
                      onKeyUp={undefined}
                      spellCheck={undefined}
                      required={undefined}
                      showError={undefined}
                    />
                  </div>
                  <div className="flex-one mt-2">
                    <h3 className="mb-2">Deployment ID</h3>
                    <TextInput
                      inputType="text"
                      placeholder="Deployment ID"
                      autoFocus={false}
                      id="client-id-3"
                      value={props.formData?.Deployment_ID}
                      inputChange={(e, name) =>
                        props.handleInputChange(e, name)
                      }
                      max={undefined}
                      min={undefined}
                      tabIndex={undefined}
                      label=""
                      description=""
                      inputClass=""
                      name="Deployment_ID"
                      disabled={false}
                      onKeyPress={() => {}}
                      useTextArea={false}
                      errorMessage=""
                      ref={undefined}
                      onPaste={undefined}
                      onFocus={undefined}
                      onBlur={undefined}
                      onKeyUp={undefined}
                      spellCheck={undefined}
                      required={undefined}
                      showError={undefined}
                    />
                  </div>
                </form>
              </div>
              <div className="fOrm-box">
                <div className="tOggle-box">
                  <div className="text mt-2">
                    <h3>{t("Integrate to Specific Agent")}</h3>
                  </div>
                  <button className="icon-button">
                    <Form.Check // prettier-ignore
                      className="custom-toggle font-weight-bold font-14 text-nowrap mt-1"
                      type="switch"
                      id="toggle-skip-summary"
                      // label="Skip Summary Review"
                      defaultChecked={props.integrateSpecificAgent}
                      onChange={() =>
                        props.setTntegrateSpecificAgent(
                          !props.integrateSpecificAgent,
                        )
                      }
                      value={String(props.integrateSpecificAgent)}
                    />
                  </button>
                </div>
                {props.integrateSpecificAgent && (
                  <div className="flex-one mt-2">
                    <Dropdown
                      placeHolder={`${t("Select Agent")}`}
                      label=""
                      defaultValue={undefined}
                      description=""
                      dropdownClassNamePrefix=""
                      isClearable={false}
                      isSearchable={false}
                      data={props.allAgents}
                      dropdownContainerClassName=""
                      onChange={(data: { value: string }) =>
                        props.setFormData({
                          ...props.formData,
                          Agent_ID: data?.value,
                        })
                      }
                      isMandatory={false}
                      value={undefined}
                      isDisabled={false}
                      menuPosition="bottom"
                      isMulti={undefined}
                      customStyles={undefined}
                      isNested={undefined}
                      onInputChange={() => {}}
                      isCreatable={undefined}
                      onCreateOption={undefined}
                      menuIsOpen={undefined}
                    />
                  </div>
                )}
                <p>
                  {t(
                    "Only enable this if you are using a single agent for all courses",
                  )}
                </p>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline-primary" onClick={closeModal}>
            {t("Cancel")}
          </Button>
          <Button
            variant="primary"
            disabled={
              !props.formData.LMS_URL ||
              !props.formData.Client_ID ||
              !props.formData.Deployment_ID
            }
            onClick={() => {
              props.handleIntegration();
              setShowsInstructionsModal(false);
              setShowsTestConnectModal(true);
            }}
          >
            {t("Next Step")}
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        className="modal-width max-content"
        aria-labelledby="contained-modal-title-vcenter"
        show={showsTestConnectModal}
        onHide={() => {}}
        centered
      >
        <IntegrationModalHeader
          modalTitle={`Enable ${toolName} in a course`}
          closeModal={props?.closeModal}
        />

        <ModalBody className="integration-modal-body">
          <div className="tw-px-4 tw-py-4 w-100">
            <div className="content-wrap-linkadmin">
              <h2 className="mb-2">
                {t(
                    "Follow the steps below to add Walter AI as a Custom link to Navbar for teachers.",
                )}
              </h2>
              <ol>
                <li>
                  {t(
                      "Go to a course that you want to add the custom link to Navbar.",
                  )}
                  <br/>
                  {t(
                      "In case you want to add the custom link to Navbar for a course template, go to",
                  )}{" "}
                  <strong>Admin Tools &gt; Course Management</strong>,{" "}
                  {t("then select")} <strong>Templates</strong>,{" "}
                  {t("and click on the template you want to edit.")}
                </li>
                <li>
                  {t("Click on")}{" "}
                  <strong>
                    Course Admin &gt; Navigation & Themes &gt; Custom Links
                  </strong>
                </li>
                <li>
                  {t("Click on")} <strong>Create Link</strong>
                </li>
                <li>
                  {t("Ensure the following fields are filled in:")}
                  <ul>
                    <li>
                      <span className="label">Name: </span>&nbsp;
                      <em>{toolNameTeacher}</em>
                      <li>
                        <span className="label">URL: </span>&nbsp;{t("Click")}{" "}
                        <strong>
                          Insert Quicklink &gt; External Learning Tools
                        </strong>{" "}
                        {t("and then select ") + " " + linkName}
                      </li>
                    </li>
                    <li>
                      <span className="label">Behavior: </span>&nbsp;
                      <em>Same window</em>
                    </li>
                    <li>
                      <span className="label">Icon: </span>&nbsp;
                      <em>
                        {
                          "Choose an icon image if you want to display the custom link as an icon"
                        }
                      </em>
                    </li>
                    <li>
                      <span className="label">Availability: </span>
                      {t("Check on appropriate options based on the need:")}
                      <ul>
                        <li>
                          <em>Share with child org units</em> {t("(if you are updating a template)")}:
                          {t("to share with child organization units.")}
                        </li>
                        <li>
                          <em>Limit to specific roles: </em>
                          {t(
                              "to limit the visibility of the custom link to specific roles",
                          )}
                          .
                        </li>
                        <li>
                          <em>Limit to specific locales: </em>
                          {t(
                              "to limit the visibility of the custom links to users with specific locales",
                          )}
                        </li>
                      </ul>
                    </li>
                    <li>
                      {t("Click on")} <strong>Save</strong>{" "}
                      {t("to create the custom link")}
                    </li>
                  </ul>
                </li>
                <li>
                  {t("Go back to")} <strong>Navbars</strong>{" "}
                  {t("and select the Navbar you want to add the custom link")}
                </li>
                <li>
                  {t("Click on")} <strong>Custom Links</strong>{" "}
                  {t("and select")} <em>Walter AI Admin</em>{" "}
                  {t("from the list")}
                </li>
                <li>
                  {t("Click on")} <strong>Save and Close</strong> to complete
                  editing the Navbar.
                </li>
              </ol>

              <h2 className="mb-2">
                {t("To add")} {toolName} {t("to course content for students, follow the steps below:")}
              </h2>
              <ol>
                <li>{t("Go to a course that you want to enable the tool")}</li>
                <li>
                  {t("Go to")} <strong>Content</strong>
                </li>
                <li>
                  {t(
                      "Under the module that you want to add the tool, click on",
                  )}{" "}
                  <strong>Existing Activities</strong>, {t("and choose")}{" "}
                  <strong>External Learning Tools</strong>
                </li>
                <li>
                  {"Select"} <em>{linkName}</em>
                </li>
              </ol>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={closeModal}>
            {t("Complete")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default Desire2learnLtiModal;
