import React, { useEffect, useMemo, useState } from 'react'
import { Image } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import DragDropimg from "../../../assets/New-images/build-drag-drop.svg";

import { useTranslation } from "react-i18next";
import { HelperService } from '../../../services/helper.service';

import UploadFilesService from "../../../services/UploadService";
const uploadService = new UploadFilesService();

interface props {
  getFileDocumentsHandler: (documents: any) => void,
  supportedTypes?: string;
  maxFiles?: number;
  message?: string;
  fileSizeMessage?: string;
  fileImage?: any;
}

export const DragAndDrop: React.FunctionComponent<props> = (props: props) => {
  const { t } = useTranslation('translation');
  const [maxFileSize, setMaxFileSize] = useState(100 * 1024 * 1024);
  const maxFileSizeinMB = useMemo(() => maxFileSize / 1048576, [maxFileSize]);
  const helperService = new HelperService();

  const getFileSizeLimit = () => {
    uploadService.getFileSizeLimit().then(({ data }) => {
      setMaxFileSize(data.data.file_size_limit)
    });
  }

  useEffect(() => {
    getFileSizeLimit();
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: files =>  props.getFileDocumentsHandler(files),
    accept: props.supportedTypes ? props.supportedTypes : '.doc, .docx, .ppt, .pptx, .pdf, .txt',
    maxFiles: props.maxFiles ? props.maxFiles : 5,
    maxSize: maxFileSize,
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach((file) => {
        if (file.file.size > maxFileSize) {
          helperService.showToaster(t(`The file size exceeds the ${maxFileSizeinMB}MB limit. Please upload a file that is ${maxFileSizeinMB}MB or smaller.`), "error" );
        }
        else if (file.errors[0]?.code === 'file-invalid-type') {
          helperService.showToaster(t('The file type you are trying to upload is not supported. Please upload a .docx, .pptx, or .pdf file.'),'error');
        }
      });
    },
  })

  const defaultMessage = t("<><b>Drop</b> your image here or click to <b>browse.</b></>")
  return (
    <div className='custom-file-wrap' {...getRootProps()}>
      <input {...getInputProps()} />
      <Image src={props.fileImage ? props.fileImage : DragDropimg} />
      <b className='pb-2 d-block mt-3'>
        <span dangerouslySetInnerHTML={{ __html: props.message ? props.message : defaultMessage }}>
        </span>
        {!props.fileSizeMessage && !props.hasOwnProperty('fileSizeMessage') && (<><br />{t("You can upload upto")} {props.maxFiles ? props.maxFiles : 5} {t("files.")}</>)}
        {(props.fileSizeMessage || props.hasOwnProperty('fileSizeMessage')) && (<><br /> {props.fileSizeMessage}</>)}
      </b>
      {/* <label>{t("Supported File types: .docx, pdf, pptx")} </label> */}
    </div>
  )
}

export default DragAndDrop