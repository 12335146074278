export const USAGETRENDS = "/usagetrends";
export const USAGETRENDS_USERSESSIONS = "/usagetrends/usersessions";
export const USAGETRENDS_USERSESSIONS_EMAILORUSERNAME =
  "/usagetrends/usersessions/:emailOrUserName/:userId";
export const OBJECTIVE = "/objective";
export const GREETING = "/greeting";
export const FALLBACK = "/fallback";
export const GREETING_BOT = "/greeting/bot";
export const GREETING_USER_GREET = "/greeting/user-greet";
export const KNOWLEDGE = "/knowledge";
export const KNOWLEDGE_UPLOAD_DOCUMENTS = "/knowledge/upload-documents";
export const KNOWLEDGE_GENERATE_QUESTIONS = "/knowledge/generate-questions";
export const KNOWLEDGE_GENERATE_QUESTIONS_ANSWERS =
  "/knowledge/generate-questions-answers";
export const KNOWLEDGE_GENERATE_ANSWERS = "/knowledge/generate-answers";
export const KNOWLEDGE_MANAGEUNANSWERED = "/knowledge/unanswered";
export const KNOWLEDGE_KEYPHRASES = "/knowledge/key-phrases";
export const MANAGE_LEARNER_QUESTIONS = "/knowledge/manage-learner-questions";

export const KNOWLEDGE_ALL = "/knowledge/all";
export const SYLLABUS = "/";
export const KNOWLEDGE_ALL_OLD = "/knowledge/list/all";
export const KNOWLEDGE_ARCHIVED = "/knowledge/archived";
export const KNOWLEDGE_UPLOAD_URL_DOCUMENTS = "/knowledge/upload-url-documents";
export const KNOWLEDGE_KNOWLEDGE_CREATE_FROM_SCRATCH =
  "/knowledge/knowledge-create-from-scratch";
export const KNOWLEDGE_KNOWLEDGE_CREATE_SPREADSHEET =
  "/knowledge/knowledge-create-spreadsheet";
export const KNOWLEDGE_REVIEW_FAQ = "/knowledge/review-faq/:documentId";
export const KNOWLEDGE_DOCUMENT_FAQ = "/document-faq/:documentId";
export const KNOWLEDGEFROMDOC = "/KnowledgeFromDoc";
export const KNOWLEDGEFROMUSERSAYS = "/KnowledgeFromUserSays";
export const CONVERSATION_FLOW = "/conversation-flow";
export const ASSESSMENT_CREATE = "/assessment-create";
export const ALL_ASSESSMENTS = "/all-assessments";
export const KNOWLEDGE_ANALYSIS = "/knowledge-analysis";
export const QUIZZES = "/quizzes";
export const FORMS = "/forms";
export const CHAT_CUSTOMISATION = "/chat-customisation";
export const USER_MANAGEMENT = "/user-management";
export const NOTIFICATION = "/notification";
export const CLASSMANAGEMENT = "/class-management";
export const ORGANISATIONUSERSADMINS = "/agent/:agentId/admins";
export const ORGANISATIONUSERS = "/organisation/users";
export const SWITCHAGENT = "/switch/agent";
export const INTEGRATION = "/integration";
export const INTEGRATION_TYPE = "/integration/:type";
export const CHOOSEAGENT = "/choose/agent";
export const MANAGEDOCUMENTS = "/manage-documents";
export const UPLOADEDDOCUMENTS = "/uploaded-documents";
export const DOCUMENTDETAILBYID = "/uploaded-documents/:documentId";
export const DOCUMENTGENERATETAGSBYID = "/documents-generate-tags";
export const DOCUMENT_GENERATETE_WAITING = "/documents-generate-waiting";
export const QUIZZDETAILSBYID = "/quiz/:quizId";
export const QUIZZALLSUBMISSIONBYID = "/quiz/:quizId/all/submissions";
export const QUIZZALLREVIEWREQUESTSBYID = "/quiz/:quizId/all/review-request";
export const QUIZZSUBMISSIONBYSUBMISSIONID =
  "/quiz/:quizId/all/submissions/:submissionId/:email";
export const QUIZZQUESTIONBREAKDOWNBYID = "/quiz/question/breakdown/:quizId";
export const QUIZDETAILBYUSER = "/quiz/:quizId/:userId";
export const OLDREPORTS = "/old-reports";
export const MANAGECATEGORY = "/category-management";
export const USER_REGISTRATION = "/organisation/users/registration/:token";
export const REVIEW_SUBMISSION = "/review-submission/:submissionId";
export const CISCO_CLIENT = "/";
export const MATCHED_UNMATCHED_QUESTIONS = "/matched-unmatched-questions";
export const REPORTS_QUIZZES = "/reports-quizzes";
export const LEARNING = "/learning";
export const DISCUSSION = "/discussion";

export const OVERVIEW = "/overview";
export const USERGROUP_CLASSES = "/usergroup/classes";
export const USERGROUP_CLASS_ACCESS = "/usergroup/class-access/:type/:id";
export const OLDREPORTS_OVERVIEW = "/reports/overview";
export const OLDREPORTS_FEEDBACK = "/reports/feedback";
export const OLDREPORTS_USERFEEDBACK = "/reports/user-feedback";
export const OLDREPORTS_TOPICS = "/reports/topics";
export const OLDREPORTS_ACTIVITY = "/reports/activity";
export const OLDREPORTS_CONTENT = "/reports/content";
export const OLDREPORTS_USERSLIST_CONVERSATIONS =
  "/reports/users-list-conversation";
export const DOCUMENT_SECTIONS = "/document-sections";
export const MULTI_DOCUMENT_SECTIONS = "/multi-document-sections"; // flow v2 summarisation
export const MULTI_DOCUMENT_SECTIONS_KANDRA =
  "/multi-document-sections-contextualised"; // flow v2 summarisation with kandra
export const SUMMARISE_DOCUMENT = "/document-summary/:questionId"; // flow v2 summarisation
export const SUMMARISE_KANDRA_DOCUMENT = "/document-summary-contextualised"; // flow v2 summarisation with kandra
export const GENERATE_ANA_SUMMARISE_DOCUMENT =
  "/document-summary/qna/:questionId"; // flow v2 summarisation
export const GENERATESUMMARISEDKEYWORDS =
  "/document-keywords-summary/qna/:questionId"; // flow v2 summarisation
export const UPLOAD_MORE = "/uploaded-documents/upload/new";
export const MANAGEKNOWLEDGEGROUP = "/manage/groups";
export const SUPPORT = "/support";
export const UPDATE_CLASS = "/update-class/:classId";
export const UPDATE_LEARNER_GROUP = "/update-learner-group/:groupId";
export const UPLOAD_LIST_OF_USERS = "/upload-list-of-users";
export const DASHBOARD = "/dashboard";

export const GET_QUIZ_BYID = "/quizzes/edit/:quizId";
export const QUIZZES_BETA = "/beta/quizzes";
export const QUIZZES_ARCHIVE_BETA = "/beta/quizzes/archived";
export const QUIZZES_GUIDE = "/quiz-guide";
export const QUIZZES_BETA_REVIEW = "/beta/quizzes-review";

export const EDIT_QUESTION_BANK = "/quizzes/bank/edit/:questionId";
export const EDIT_QUESTIONS_BANK = "/quizzes/bank/questions/edit";
export const CREATE_QUESTION_BANK = "/quizzes/bank/create";
export const IMPORT_QUIZ = "/import-quiz";
export const CREATE_QUIZ_FROM_UPLOADED_DOCUMENTS = "/upload-document";
export const CREATE_QUIZ_FROM_QUESTION_ID = "/quiz-questions/:questionId";
export const IMPORTQUESTIONBANK = "/import-questions-bank";
export const IMPORTQUESTIONBANK_PREVIEW =
  "/import-questions-bank/preview/:docId";
export const IMPORTQUESTIONBANK_PREVIEWS_TAG =
  "/import-questions-bank/preview-tags";
export const IMPORTQUESTIONBANK_PREVIEWS_QUESTIONS =
  "/import-questions-bank/questions-review";

export const REPORTS_QUIZZES_ALL_SUBMISSIONS =
  "/report-quizzes-all-submissions";
export const BUILD_KNOWLEDGE_V2 = "/build/:step/knowledge";
export const BUILD_MULTI_DOCUMENTS_SECTION =
  "/build/:step/multi-documents-section";
export const BUILD_SUMMARISE_MULTI_DOCUMENTS_SECTION =
  "/build/:step/:subStep/multi-documents-section";
export const BUILD_SUMMARISE_DOCUMENT = "/build/:step/document-summary";
export const SUMMARISE_INTO_SINGLE_OR_MULTIPLE_FILE =
  "/build/documents-summary";
// export const CREATE_INDIVIDUAL_SUMMARY = '/build/:step/:subStep/documents-individual-summary/:questionId';
export const LEARNING_OUTCOMES = "/build/learning-outcomes";
export const LEARNING_OUTCOMES_REVIEW = "/build/learning-outcomes-review";
export const LEARNING_OUTCOMES_UPDATE = "/update-learning-outcomes";

export const GENERATE_KNOWLEDGE = "/build/:step/generate-knowledge/:questionId";
export const GENERATE_QNA = "/build/:step/generate-knowledge";
export const BUILD_FINAL = "/build/final";
export const SELF_SIGNUP = "/signup";
export const AWS_SELF_SIGNUP = "/aws-signup";
export const TEAM_DASH = "/msteams/dashboard";

export const EXPORT_QUESTION_BANK = "/export-question-bank";
export const ONBOARD = "/";
export const PLANS = "/manage-plan";

export const CREATE_SMART_QUIZ = "/toolkit/create-quiz/smart";
export const SMART_QUIZ_SELECT_PAGES = "/toolkit/create-quiz/select-pages";
export const SMART_QUIZ_PROCESS_DOCUMENTS =
  "/toolkit/create-quiz/process-documents";
export const TOOLKIT_SMART_QUIZ_SUMMARISE_DOCUMENTS =
  "/toolkit/create-quiz/summarise-documents";
export const REVIEW_QUIZ_QUESTIONS = "/toolkit/create-quiz/review-quiz";
export const GUIDED_QUIZESS_IMPORT = "/toolkit/create-quiz/guided/import-quiz";

export const TOOLKIT_GENERATE_QNA_UPLOAD_DOC =
  "/toolkit/generate-qna/upload-document";
export const TOOLKIT_GENERATE_QNA_SELECT_PAGES =
  "/toolkit/generate-qna/select-pages";
export const TOOLKIT_GENERATE_QNA_PROCESS_DOCUMENTS =
  "/toolkit/generate-qna/process-documents";
export const TOOLKIT_GENERATE_QNA_SUMMARISE_DOCUMENTS =
  "/toolkit/generate-qna/summarise-documents";
export const TOOLKIT_GENERATE_QNA_LEARNING_OUTCOMES =
  "/toolkit/generate-qna/review-learning-outcomes";
export const TOOLKIT_GENERATE_QNA = "/toolkit/generate-qna";
export const TOOLKIT_GENERATE_QNA_FINAL = "/toolkit/generate-qna/final";

export const TOOLKIT_SUMMARISE_DOCUMENTS = "/toolkit/summarise-documents";
export const TOOLKIT_SUMMARISE_DOCUMENTS_SELECT_PAGES =
  "/toolkit/summarise-documents/select-pages";
export const TOOLKIT_SUMMARISE_DOCUMENTS_SINGLE_MULTIPLE =
  "/toolkit/summarise-documents/summarise";
export const TOOLKIT_SUMMARISE_DOCUMENTS_FINAL =
  "/toolkit/summarise-documents/final";

export const TOOLKIT_GENERATE_LEARNING_OUTCOMES =
  "/toolkit/generate-learning-outcomes";
export const TOOLKIT_GENERATE_LEARNING_OUTCOMES_SELECT_PAGES =
  "/toolkit/generate-learning-outcomes/select-pages";
export const TOOLKIT_GENERATE_LEARNING_OUTCOMES_PROCESS_DOCS =
  "/toolkit/generate-learning-outcomes/process-documents";
export const TOOLKIT_GENERATE_LEARNING_OUTCOMES_SUMMARISE_DOCUMENTS =
  "/toolkit/generate-learning-outcomes/summarise-documents";
export const TOOLKIT_GENERATE_LEARNING_OUTCOMES_REVIEW =
  "/toolkit/generate-learning-outcomes/review-outcomes";
export const TOOLKIT_GENERATE_LEARNING_OUTCOMES_FINAL =
  "/toolkit/generate-learning-outcomes/final";
export const TOOLKIT_SETUP_CONTEXTUALISED_CHAT =
  "/toolkit/setup-contextualise-chat";
export const TOOLKIT_SETUP_CONTEXTUALISED_SUMMARISED_FILE =
  "/toolkit/setup-contextualise-chat/summary-document";
export const TOOLKIT_SETUP_CONTEXTUALISED_CHAT_WAITING =
  "/toolkit/setup-contextualise-chat/process-documents";
export const TOOLKIT_SETUP_CONTEXTUALISED_CHAT_FINAL =
  "/toolkit/setup-contextualise-chat/follow-up";
export const RESPONSES = "/responses";

export const BUILD_KNOWLEDGE_V2_SELECT = "/build/knowledge/select";
export const BUILD_DOCUMENT_PROCESS = "/build/process-documents";

export const INTEGRATED_SOURCES = "/toolkit/import-from-integrated-sources";

export const INTEGRATED_SOURCES_SELECT_PAGES =
  "/toolkit/integrated-sources/select-pages";

export const INTEGRATED_SOURCES_PROCESS_DOCUMENTS =
  "/toolkit/integrated-sources/process-documents";

export const INTEGRATED_SOURCES_REVIEW_QUESTIONS =
  "/toolkit/integrated-sources/review-questions";

export const CHANGE_ORGANISATION = "/change-organisation";

export const KNOWLEDGE_SETUP = "/knowledge/setup/:id";

export const SETUP_CONTEXTUALISED_CHAT = "/knowledge/setup/chat";

