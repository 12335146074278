import {Image, OverlayTrigger, Tooltip} from "react-bootstrap";
import copyIcon from "../../../assets/images/copy-icon.svg";
import React, {useState} from "react";

import "./index.scss";
import {CopyContant} from "../../../utils/copyContant";
import {useTranslation} from "react-i18next";

interface CopyProps {
  text: string;
}

export default function CopyIcon(props: CopyProps) {
  const { t } = useTranslation("translation");
  const [tooltipMessage, setTooltipMessage] = useState(t("Copy"));

  const handleChangeTooltip = async (text: string) => {
      await CopyContant(text);
      setTooltipMessage(t("Copied"));
      setTimeout(() => {
        setTooltipMessage(t("Copy"));
      }, 500);
    };

    const renderTooltip = (props: any) => (
      <Tooltip id="button-tooltip" {...props}>
        {t(tooltipMessage)}
      </Tooltip>
    );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <Image
        className="cursor-pointer"
        onClick={() => handleChangeTooltip(props.text)}
        src={copyIcon}
      />
    </OverlayTrigger>
  );
}
