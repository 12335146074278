import { Button, Modal, ModalFooter } from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import { useTranslation } from "react-i18next";
import { FormData } from "../IntegrationType";
import IntegrationModalHeader from "../Modal/IntegrationModalHeader";
import TextInput from "../../../components/UI/OldFormBuilder/TextInput";

type ApiSetup = {
  integrationType: string;
  closeModal: () => void;
  renderCopy: (text: string) => JSX.Element;
  handleIntegration: () => Promise<void>;
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => void;
};

const MoodleApiModal = (props: ApiSetup) => {
  const { t } = useTranslation("translation");

  return (
    <Modal
      className="modal-width"
      aria-labelledby="contained-modal-title-vcenter"
      show={true}
      onHide={() => {}}
      centered
    >
      <IntegrationModalHeader
        modalTitle={t("Enable Content Integration with Moodle")}
        closeModal={props?.closeModal}
      />

      <ModalBody className="integration-modal-body">
        <div className="tw-flex  tw-gap-4 canvas-manual-integration">
          <div className="tw-px-4 tw-py-4 w-90 left-content-wrap">
            <p className="mb-0 subhEad">
              {t(
                "To integrate Noodle Factory with Moodle, first login Moodle as admin role.",
              )}
            </p>
            <br />

            <h2 className="mb-2">
              {t(
                "Step 1: Enable web service and configure web service user in Moodle",
              )}
            </h2>
            <ul>
              <li>
                {t(
                  "Follow instructions in this link from Moodle to enable web services and REST protocol",
                )}
                :&nbsp;
                <a
                  className="text-primary"
                  href="https://docs.moodle.org/405/en/Using_web_services"
                  target="_blank"
                >
                  Using web services - MoodleDocs
                </a>
              </li>
              <li>
                {t("Enable web services authentication")}
                :&nbsp;
                <a
                  className="text-primary"
                  href="https://docs.moodle.org/405/en/Web_services_authentication"
                  target="_blank"
                >
                  Web services authentication - MoodleDocs
                </a>
              </li>
            </ul>

            <h2 className="mb-2">
              {t("Step 2: Create a dedicated user for web service usage")}
            </h2>
            <ul>
              <li>
                Go to{" "}
                <strong>
                  Site administration &gt; Users &gt; Browse list of users
                </strong>
              </li>
              <li>
                Click on button <strong>Add a new user</strong> to create a new
                user for the integration
              </li>
              <li>
                Complete the form to create a new user. In the dropdown{" "}
                <strong>Choose an authentication method</strong>, choose{" "}
                <em>Web services authentication</em>
              </li>
              <li>
                Click on button <strong>Create user</strong> to create the new
                user
              </li>
            </ul>

            <h2 className="mb-2">
              {t("Step 3: Create a new external custom service")}
            </h2>
            <ul>
              <li>
                Go to{" "}
                <strong>
                  Site administration &gt; Server &gt; External services
                </strong>
              </li>
              <li>
                Under section <strong>Custom services</strong>, click on button{" "}
                <strong>Add</strong>
              </li>
              <li>
                <p className="mb-0 subhEad">
                  {t("Complete the form with the following details")}:
                </p>
                <ul>
                  <li>
                    <span className="label">Name: </span>
                    {props.renderCopy("Noodle Factory Content Integration")}
                  </li>
                  <li>
                    <span className="label">Short name: </span>
                    &nbsp;Leave it empty
                  </li>
                  <li>
                    Tick on checkbox <em>Enable</em>
                  </li>
                  <li>
                    Click on link <strong>Show more</strong>, then tick on
                    checkbox <em>Can download files</em>
                  </li>
                  <li>
                    Click on <strong>Add service</strong> to complete adding the
                    new custom service
                  </li>
                </ul>
              </li>
            </ul>

            <h2 className="mb-2">
              {t("Step 4: Add functions to the newly created custom service")}
            </h2>
            <ul>
              <li>
                Go back to list of <strong>External service</strong>
              </li>
              <li>
                Under section <strong>Custom services</strong>, click on link{" "}
                <strong>Functions</strong> in the newly created custom service
              </li>
              <li>
                Click on Add functions, and add the following functions:
                <ul>
                  <li>
                    <em>core_course_get_contents</em>
                  </li>
                  <li>
                    <em>core_course_get_courses</em>
                  </li>
                  <li>
                    <em>core_course_get_module</em>
                  </li>
                  <li>
                    <em>mod_resource_get_resources_by_courses</em>
                  </li>
                </ul>
              </li>
              <li>
                Click on <strong>Add functions</strong> to save changes.
              </li>
            </ul>

            <h2 className="mb-2">
              {t("Step 5: Add a new service token for the integration")}
            </h2>
            <ul>
              <li>
                Go to{" "}
                <strong>
                  Site administration &gt; Server &gt; Manage tokens
                </strong>
              </li>
              <li>
                Click on button <strong>Create token</strong>, and fill in the
                form as following:
                <ul>
                  <li>
                    <span className="label">Name: </span>
                    {props.renderCopy(
                      "Noodle Factory Content Integration Token",
                    )}
                  </li>
                  <li>
                    <span className="label">User: </span>
                    {t("Select the user created in step 2")}
                  </li>
                  <li>
                    <span className="label">Service: </span>
                    {t("Select the custom service created in step 3")}
                  </li>
                  <li>
                    <span className="label">IP restriction: </span>
                    {"leave it blank"}
                  </li>
                  <li>
                    <span className="label">Valid until: </span>
                    {"set a proper date"}
                  </li>
                  <li>
                    Click on <strong>Save changes</strong> to create the new
                    token
                  </li>
                </ul>
              </li>
              <li>
                Copy the value of the newly created token and complete the form
                on the right:
                <ul>
                  <li>
                    <em>Moodle LMS URL</em>:{" "}
                    {t("This is the URL of your Moodle LMS instance")}
                  </li>
                  <li>
                    <em>Service Token</em>:{" "}
                    {t(
                      "This is the token value you copied from the newly created token",
                    )}
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="whit-box tw-px-4 right-content-wrap w-10">
            <div>
              <h2 className="mb-2">
                {t("Required fields for Noodle Factory")}
              </h2>
              <p className="mb-0">
                {t(
                  "As you complete each step, please fill up following values to set up the integration on Noodle Factory",
                )}
              </p>
              <form>
                <div className="flex-one mt-2">
                  <h3 className="mb-2">Moodle LMS URL</h3>
                  <TextInput
                    inputType="text"
                    placeholder="Moodle LMS URL"
                    autoFocus={false}
                    id="client-id-1"
                    value={props.formData?.LMS_URL}
                    inputChange={(e, name) => props.handleInputChange(e, name)}
                    max={undefined}
                    min={undefined}
                    tabIndex={undefined}
                    label=""
                    description=""
                    inputClass=""
                    name="LMS_URL"
                    disabled={false}
                    onKeyPress={() => {}}
                    useTextArea={false}
                    errorMessage=""
                    ref={undefined}
                    onPaste={undefined}
                    onFocus={undefined}
                    onBlur={undefined}
                    onKeyUp={undefined}
                    spellCheck={undefined}
                    required={undefined}
                    showError={undefined}
                  />
                </div>
                <div className="flex-one mt-2">
                  <h3 className="mb-2">Client Secret</h3>
                  <TextInput
                    inputType="text"
                    placeholder="Service Token"
                    autoFocus={false}
                    id="client-id-3"
                    value={props.formData?.Service_Token}
                    inputChange={(e, name) => props.handleInputChange(e, name)}
                    max={undefined}
                    min={undefined}
                    tabIndex={undefined}
                    label=""
                    description=""
                    inputClass=""
                    name="Service_Token"
                    disabled={false}
                    onKeyPress={() => {}}
                    useTextArea={false}
                    errorMessage=""
                    ref={undefined}
                    onPaste={undefined}
                    onFocus={undefined}
                    onBlur={undefined}
                    onKeyUp={undefined}
                    spellCheck={undefined}
                    required={undefined}
                    showError={undefined}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </ModalBody>
      {/* {popUpState === "" && ( */}
      <ModalFooter>
        <Button variant="outline-primary" onClick={props.closeModal}>
          {t("Cancel")}
        </Button>
        <Button
          variant="primary"
          disabled={!props.formData.LMS_URL || !props.formData.Service_Token}
          onClick={() => {
            props.handleIntegration();
            props.closeModal();
          }}
        >
          {t("Complete")}
        </Button>
      </ModalFooter>
      {/* )} */}
    </Modal>
  );
};
export default MoodleApiModal;
